<!--
 * @Author       : Hugo
 * @Date         : 2021-10-25 15:02:04
 * @LastEditTime : 2022-07-08 15:37:39
 * @LastEditors  : hugo
 * @FilePath     : /miaohang/src/components/dialog/msgUnit.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="dialog_msg_receive_unit"
  >
    <!-- 时间 -->
    <div
      v-if="show_time"
      class="time_group"
    >
      {{ msg_data.createTime | universalDate }}
    </div>
    <!-- 文件消息 -->
    <div
      v-if="isFileMessage"
      class="wrapper_group"
      :class="[type]"
    >
      <div
        v-if="type === 'send'"
        class="placeholder"
      ></div>
      <div
        class="container_group"
      >
        <!-- 其他人头像 -->
        <div
          v-if="type === 'receive'"
          class="avatar_group"
        >
          <AvatarDetail
            :type="type"
            :msg_data="msg_data"
          ></AvatarDetail>
        </div>
        <!-- 消息内容 -->
        <div
          class="content_group"
          :class="[type]"
        >
          <!-- 更新用户的名字 -->
          <div
            v-if="type === 'receive'"
            class="username bbox cp"
            :class="{open_at: chat_type === 1}"
          >
            <span
              class="at"
              @click.stop="atUser();"
            >
              点此@{{ userName }}
            </span>
            <div class="text">
              {{ userName }}
            </div>
          </div>
          <!--  图片 -->
          <picture-message
            :msg-data="msg_data"
          ></picture-message>
        </div>
        <!-- 自己头像 -->
        <div
          v-if="type === 'send'"
          class="avatar_group"
        >
          <AvatarDetail :type="type" :msg_data="$tools.getCookie('user')"></AvatarDetail>
        </div>
      </div>
      <div class="placeholder" v-if="type == 'receive'"></div>
    </div>
    <!-- [非弱消息] -->
    <div
      class="wrapper_group"
      :class=[type]
      v-if="is_chat || (sketch_data.details && sketch_data.details.length) || is_normal"
    >
      <div class="placeholder" v-if="type=='send'"></div>
      <div class="container_group" >
        <!-- 其他人头像 -->
        <div class="avatar_group" v-if="type=='receive'">
          <AvatarDetail :type="type" :msg_data="msg_data"></AvatarDetail>
        </div>
        <!-- 消息内容 -->
        <div class="content_group" :class=[type]>
          <!-- 更新用户的名字 -->
          <div
            class="username bbox cp"
            :class="{open_at: chat_type === 1}"
            v-if="type =='receive'"
          >
            <span
              class="at"
              @click.stop="atUser();"
            >
              点此@{{ userName }}
            </span>
            <div class="text">
               {{ userName }}
            </div>
          </div>
          <!-- 聊天 -->
          <template
            v-if="is_chat"
          >
            <sketch-chat
              :msg="msg_data"
              :type="type"
            ></sketch-chat>
            <!-- 教学 与我有关等标签 -->
            <div class="tag_group">
              <div class="tag_box">
                <div class="tag privacy" v-if="msg_data.hide">私</div>
              </div>
            </div>
          </template>
          <!-- 消息简述 -->
          <template
            v-if="is_normal"
          >
            <!-- 标题 -->
            <!-- 创建和更新区分时  :class="{[type]:true,create: is_create}" -->
            <div
              class="desc_group cp d-flex"
              :class="{[type]:true}"
              v-if="msg_data.taskName"
              @click="openTaskBox"
            >
              <div class="color_group">
                <div class="color"></div>
              </div>
              <div class="desc_title_group d-flex align-center">
                <title-status-tag
                  class="mr-5"
                  size="18px"
                  :process-status="String(msg_data.processStatus)"
                  :level-status="msg_data.levelStatus"
                ></title-status-tag>
                <div class="desc_title">
                  {{ msg_data.taskName }}
                </div>
              </div>
              <div class="reply cp un_sel"
                :class="[type]"
                v-if="isLinkMe || (type === 'send')"
                @click.stop="replyChat();"
              >回复</div>
              <!-- <div class="reply cp un_sel"
                :class="[type]"
                v-if="type=='receive' && msg_data.linkme"
                @click.stop="replyChat();"
              >回复</div> -->
            </div>
            <!-- 创建 [关闭该功能] -->
            <template
              v-if="is_create && 0"
            >
              <div
                class="msg_main task"
                :class="{delete: msg_data.deleteFlag, create: is_create}"
                @click="openTaskBox({go_to_default: true})"
              >
                <create-user
                  :createtousers="msg_data.details[0].createtousers"
                ></create-user>
              </div>
              <!-- 教学 与我有关等标签 -->
              <div class="tag_group">
                <div class="tag_box">
                  <div class="tag teaching" v-if="msg_data.taskType == 3">教</div>
                  <div class="tag privacy" v-if="msg_data.hide">私</div>
                  <div class="tag reminder" v-if="msg_data.isremind"></div>
                </div>
                <div class="attended_group" v-if="isLinkMe && type=='receive'">
                  <div class="icon"></div>
                  <div class="text">与我有关</div>
                </div>
              </div>
            </template>
            
            <!-- 其他 -->
            <template
            >
              <!-- 文本类型消息 -->
              <div
                class="msg_main task"
                :class="{delete: sketch_data.deleteFlag}"
                @click="openTaskBox({go_to_default: true})"
              >
                <create-user
                  v-if="is_create"
                  :createtousers="msg_data.details[0].createtousers"
                ></create-user>
                <!-- <div class="text_line cp un_sel" v-html="formatDescription(sketch_data.work)"></div> -->
                <template
                  v-for="(item, index) in sketch_data.details"
                > 
                  <div
                    class="text_line cp un_sel"
                    :key="index"
                    @click.stop="setDesignationControlId(item);"
                    v-if="index < max_limit"
                  >
                    <!-- 第一个 || !父控件相等且存在 -->
                    <sketch-title
                      v-if="index === 0 || !( (sketch_data.details[index-1].conIdP === item.conIdP) && item.conIdP  )"
                      :log="sketch_data"
                      :msg="item"
                      @handleAppoint="openAppointTask"
                    ></sketch-title>
                    <sketch-title-change
                      :msg="item"
                      @handleAppoint="openAppointTask"
                    ></sketch-title-change>
                    <sketch-content
                      :msg="item"
                      @handleAppoint="openAppointTask"
                    ></sketch-content>
                    <!-- <div class="brief_content_group" v-text="item.content"
                      v-else
                    ></div> -->
                  </div>
                </template>
                <template
                  v-if="can_switch_brief"
                >
                  <div class="more_brief"
                    v-show="!switch_brief"
                  >...</div>
                  <div
                    class="switch_brief cp"
                    @click.stop="switch_brief = !switch_brief"
                    v-text="switch_brief ? '收起' : '展开'"
                  >收起</div>
                </template>
              </div>
              <!-- 教学 与我有关等标签 -->
              <div class="tag_group">
                <div class="tag_box">
                  <div class="tag teaching" v-if="msg_data.taskType === 3">教</div>
                  <div class="tag privacy" v-if="msg_data.hide">私</div>
                  <div class="tag reminder" v-if="msg_data.isremind"></div>
                </div>
                <div class="attended_group" v-if="isLinkMe && type==='receive'">
                  <div class="icon"></div>
                  <div class="text">与我有关</div>
                </div>
              </div>
            </template>
          </template>
        </div>
        <!-- 自己头像 -->
        <div class="avatar_group" v-if="type == 'send'">
          <AvatarDetail :type="type" :msg_data="$tools.getCookie('user')"></AvatarDetail>
        </div>
      </div>
      <div class="placeholder" v-if="type == 'receive'"></div>
    </div>
    <!-- 弱消息 -->
    <template
      v-if="weaken_data"
    >
      <weaken-content
        :msg="weaken_data"
      ></weaken-content>
    </template> 
    <!-- 公示板 加减人 -->
    <template
      v-if="is_work"
    >
      <weaken-content
        type="work"
        :msg="msg_data"
      ></weaken-content>
    </template> 
  </div>
</template>

<script>
import tools from '@/api/tools'
import store from '@/store'
import TitleStatusTag from '@/components/Task/Main/LineBar/TaskTitle/TitleStatusTag'
import AvatarDetail from '@/components/basic/avatarDetail.vue'
import SketchContent from '@/components/dialog/Sketch/Content/Content.vue'
import CreateUser from '@/components/dialog/Sketch/Content/CreateUser.vue'
import WeakenContent from '@/components/dialog/Weaken/Content.vue'
import SketchTitle from '@/components/dialog/Sketch/Title/Title.vue'
import SketchTitleChange from '@/components/dialog/Sketch/Title/Change.vue'
import SketchChat from '@/components/dialog/Sketch/Chat/Content.vue'
import { UserInfo } from '@/types'
import PictureMessage from '@/components/dialog/FileMessage/PictureMessage'
export default {
  components:{
    PictureMessage,
    AvatarDetail,
    SketchContent,
    CreateUser,
    WeakenContent,
    SketchTitle,
    SketchTitleChange,
    SketchChat,
    TitleStatusTag
  },
  data(){
    return {
      // 展开/收起 f=>收起
      switch_brief: false,
      chat_type: Number(this.$tools.getChatType()),
      loading: false,
    }
  },
  props: {
    /**
     * 消息类型 receive 接收 | send 发送
     */
    type: {
      type: String,
      default: 'send'
    },
    msg_data: {
      type: Object,
      default: null,
    },
    prev: {
      type: Object,
      default: undefined,
    }
  },
  computed:{
    isFileMessage() {
      const { msg_data } = this
      const { logType } = msg_data
      return logType === 6
    },
    userName() {
      const { state } = store
      const { friends_list } = state
      const { msg_data } = this
      const { userName, fromUserId } = msg_data
      if (!friends_list || friends_list.length === 0) {
        return userName
      }
      const aim = friends_list.find((item) => {
        return Number(item.friendUserId) === Number(fromUserId)
      })
      // 返回好友备注名
      if (aim) {
        const { friendRemark } = aim
        return friendRemark ?? userName ?? aim.userName
      }
      return userName
    },
    is_create(){
      const {msg_data} = this;
      const {details} = msg_data;
      if(details && details[0] && details[0].controlType === 101 && details[0].type === 1){
        return true;
      }
      return false;
    },
    is_chat(){
      const {msg_data } = this;
      const {logType} = msg_data;
      if(logType === 3 || logType === 4){
        return true;
      }
      return false;
    },
    is_normal(){
      const {sketch_data, is_chat, is_work} = this;
      // if(is_weaken){
        // return false;
      // }
      const details = sketch_data.details||[];
      if(details.length == 0){
        return false;
      }
      if(is_chat || is_work){
        return false;
      }
      return true;
    },
    is_work(){
      const {msg_data} = this;
      const {work, logType} = msg_data;
      if(work && logType === 2){
        return true;
      }
      return false;
    },
    // 最大展示数量限制
    max_limit(){
      let num = 3;
      let {sketch_data,switch_brief, is_weaken} = this;
      let {details} = sketch_data || {};
      if(!switch_brief){
        num = 3;
      }else{
        if(details && details.length){
          num = details.length;
        }
      }
      return num;
    },
    // 是否可以显示展开收起
    can_switch_brief(){
      let status = false;
      let {sketch_data} = this;
      let {details} = sketch_data || {};
      if(details && details.length){
        if(details.length > 3){
          status = true;
        }
        
      }
      return status;
    },
    /**
     * 是否含有弱消息
     */
    is_weaken() {
      const { msg_data, is_chat } = this
      const { details, deleteFlag } = msg_data
      // // console.log(details, details.length, is_chat, deleteFlag)
      if (!details) {
        return false
      }
      // if(!details.length){
      //   return false;
      // }
      if (is_chat) {
        return false
      }
      if (deleteFlag === 1) {
        return true
      }
      let status = false
      details.forEach((item) => {
        const { controlType, type } = item
        if (type === 6) { // 自动结束投屏
          status = true
        }
        if (type === 5) { // 结束投屏
          status = true
        }
        if (type === 4) { // 开始投屏
          status = true
        }
        // 删除控件
        if (type === 3) {
          status = true
        }
        // 更新参与人
        if (controlType === 102) {
          status = true
        }
        // 控件顺序
        if (controlType === 103) {
          status = true
        }
        if (controlType === 105) {
          status = true
        }
      })
      return status;

    },
    sketch_data(){
      const {msg_data, is_weaken} = this;
      if(is_weaken){
        const sketch_data = this.$tools.deepClone(msg_data);
        const {details} = msg_data;
        sketch_data.details = [];
        details.forEach(item=>{
          const isSketch = !this.determineDetailType(item);
          if(isSketch){
            sketch_data.details.push(item);
          }
        })
        return sketch_data;
      }
      return msg_data;
    },
    weaken_data(){
      const {msg_data, is_weaken} = this;
      if(is_weaken){
        const weaken_data = this.$tools.deepClone(msg_data);
        const {details} = msg_data;
        weaken_data.details = [];
        details.forEach(item=>{
          const isWeaken = this.determineDetailType(item);
          if(isWeaken){
            weaken_data.details.push(item);
          }
        })
        return weaken_data;
      }
      return null;

    },
    show_time(){
      const {prev, msg_data} = this
      if(prev === undefined){
        return true
      }
      if(!prev.createTime){
        return true
      }
      if(msg_data.createTime - prev.createTime < 2*60*1000){
        return false
      }
      return true
    },
    // 是否与我有关
    isLinkMe() {
      const { msg_data } = this
      const userId = this.$tools.getUserId()
      const { tousers } = msg_data
      if ((tousers??'') === '') {
        return false
      }
      const arr = tousers.split(',')
      let isLinkMe = false
      isLinkMe = arr.includes(String(userId))
      return isLinkMe
    }
    

  },
  mounted(){
  },
  methods: {
    /**
     * @description: 打开消息盒子
     * @param {Object} params {go_to_default: 是否跳转到默认第一个控件位置, appoint_widget:指定控件数据}
     * @return {*}
     */
    async openTaskBox (params) {
      const { loading } = this
      if (loading) {
        return
      }
      this.loading = true
      let { go_to_default, appoint_widget, taskLog } = params || {}
      // // console.log(this.msg_data);
      if (this.$api.isDeleteTask(this.msg_data)) {
        this.loading = false
        return false
      }
      let routename = this.$route.name
      // let chat_id = this.$route.params.id;
      let chat_id = this.$api.moduleTask.getRouteParamsId()
      let chat_type = ''
      let { msg_data } = this
      let { details } = msg_data
      if (routename == 'fun_task_dialog_single') {
        chat_type = 0
      } else if (routename == 'fun_task_dialog_group') {
        chat_type = 1
      }
      // 清空未读消息 TODO 判断是否有未读
      if (chat_type != '' && chat_id) {
        this.$http.request('/projectTask/unreadTozero',{chat_id, chat_type})
      }
      let config = {}
      config.edit = true
      if (appoint_widget) {
        config.appoint_widget = appoint_widget;
        // 打开关联时 全部调用;
        if(appoint_widget.parent_id){
          config.relevancy = true;
          this.$api.moduleTask.readedTask({taskId: this.msg_data.taskId}); // 已读处理
        }
      }
      config.task_log = taskLog
      await this.$api.moduleTask.openTask({
        taskId: this.msg_data.taskId,
        config,
        currentChatId: chat_id,
        callback: this.handlerMigrate
      })
      this.loading = false
      /** 跳转到指定位置 [默认第一个] */
      if (go_to_default) {
        if(details && details.length){
          let aim = details.find( item => {
            return item.type != 3 && (item.controlType != 102 || item.controlType != 103);
          })
          if(aim){
            this.$store.commit('set_task_latest_control_id', aim.conId);
          }
        }
      }
    },
    /**
     * 任务被迁移的处理
     */
    handlerMigrate (params) {
      const { msg_data } = this
      const { taskId } = msg_data
      const { message_list, my_task_list } = store.state
      // 提示：已迁移
      this.$openConfirm({
        title: '该任务被迁移至其他工作组，已自动清除',
        desc: '是否跳转至该工作组查看？',
        onSure: () => {
          try {
            const { task } = params.contents
            const { chatId } = task
            this.$router.push({
              name: 'fun_task_dialog_group',
              params: {
                id: tools.btoa(chatId)
              }
            })
          } catch (e) {
            console.log(e)
          }
        }
      })
      // 清除当前message_list的相关任务
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const taskLogIndex = message_list.findIndex((unit) => {
            return Number(unit.taskId) === Number(taskId)
          })
          if (taskLogIndex !== -1) {
            message_list.splice(taskLogIndex, 1)
          } else {
            break
          }
        }

      } catch (e) {
        console.log(e)
      }
      // 清除任务区的相关任务
      try {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const myTaskListIndex = my_task_list.findIndex((unit) => {
            return Number(unit.id) === Number(taskId)
          })
          if (myTaskListIndex !== -1) {
            my_task_list.splice(myTaskListIndex, 1)
          } else {
            break
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * @description: 设置指定控件id [用于打开后跳转]
     * @param {*}
     * @return {*}
     */
    async setDesignationControlId(item){
      // // console.log(item);
      // console.log('setDesignationControlId')
      this.$store.commit('set_task_latest_control_id', item.conId);
      await this.openTaskBox();
      // this.$store.commit('set_task_latest_control_id', aim.conId);
    },
    /**
     * @description: 判断detail类型
     * @param {*}
     * @return {boolean} true => 弱类型 : false => 普通类型
     */    
    determineDetailType(item){
      const { controlType, type } = item;
      if(type === 6){ // 自动(系统)结束投屏
        return true
      }
      if(type === 5){ // 结束投屏
        return true
      }
      if(type === 4){ // 开始投屏
        return true
      }
      // 删除控件
      if(type === 3){
        return true;
      }
      // 更新参与人
      if(controlType === 102){
        return true;
      }
      // 控件顺序
      if(controlType === 103){
        return true;
      }
      if (controlType === 105) {
        return true
      }
      return false;
    },
    openAppointTask(data){
      // console.log(data);
      // console.log(this.msg_data)
      this.openTaskBox({appoint_widget: data, taskLog: this.msg_data});
    },
    /**
     * 回复任务聊天
     */
    replyChat(){
      const {commit} = this.$store;
      commit('set_dialog_fun_status', 'text');
      commit('set_dialog_fun_chat_data', this.msg_data);

    },
    atUser(){
      const {fromUserId} = this.msg_data;
      const {commit, state} = this.$store;
      const {chat_user_list,dialog_user_list, dialog_users} = state;
      // console.log({chat_user_list,dialog_user_list, dialog_users});
      if(dialog_user_list){
        const aim = dialog_user_list.find(item=>{
          return item.userId === fromUserId;
        })
        if(aim){
          const userinfo = new UserInfo(aim);
          commit('set_dialog_fun_status', 'text');
          this.$api.at.clickAtUser({username: userinfo.atName()});
          return;
        }
      }
      commit('set_dialog_fun_status', 'text');
      this.$api.at.clickAtUser({username: this.msg_data.userName});
      // // console.log(this.msg_data);
    },
  }
}
</script>

<style lang="scss" scoped>
@mixin brief_action($action){
  @if $action == create{
    color: #00651C;
  } @else if $action == update{
    color: #DD8300;
  } @else if $action == delete{
    color: #C85454;
  }
}
@mixin brief_type($type){
  &.brief_type_#{$type} .icon{
    @include background;
    background-image: url(~@/assets/images/dialog/brief_#{$type}.png);
  }
}
.dialog_msg_receive_unit{
  .time_group{
    width: 100%;
    text-align: center;
    font-size:12px;
    font-weight:400;
    color:#aaa;
    padding-bottom: 20px;
  }
  .wrapper_group{
    @include flexsb;
    width:100%;
    &.send{
      .container_group{
        justify-content: flex-end;
      }
    }
    .container_group{
      @include flex;
      align-items: flex-start;
      // overflow: hidden;
      width: 100%;
      flex: 1;
      .avatar_group{
        width: 44px;
        height: 44px;
        background-color: $img_color;
        border-radius: 4px;
        display: block;
        flex-shrink: 0;
        .avatar{
           width: 44px;
          height: 44px;
          background-color: $img_color;
          border-radius: 4px;
          display: block;
        }
      }
      
     
      .content_group{
        @include flex;
        @include bbox;
        // padding-left: 15px;
        flex-direction: column;
        align-items: stretch;
        // flex:1;
        // overflow: hidden;
        // width:0;
        &.created{
          width: 350px;
        }
        &.receive{
          padding-left:15px;
          .msg_main{
            background-color:#fff;
            border-radius:0px 4px 4px 4px;
            color:#555;
            .tag_group{
              right: 0;
              .tag{
                right: 3px;
              }
            }
            &::before{
              left: -5px;
            }
          }
        }
        &.send{
          padding-right: 15px;
          .msg_main{
            border-radius:4px 0px 4px 4px;
            // background-color: #DCE6F7;
            background-color: #fff;
            color:#666;
            .tag_group{
              left: 0;
              background-image: url(~@/assets/images/dialog/send_tag.png);
              .tag{
                left: 3px;
              }
            }
            &::before{
              right: -5px;
              background-image: url(~@/assets/images/dialog/send_arrow.png);
            }
            &.task{
              .text_line{
                .desc_line{
                  color: #666;
                }
                .content_line{
                  color: #555;
                }
              }
            }
            
          }
        }
        .msg_main{
          @include bbox;
          padding: 12px;
          position: relative;
          z-index: 2;
          font-size:14px;
          border-radius: 4px 4px 10px 10px !important;
          
          font-weight:400;
          line-height:20px;
          margin-bottom: -10px;
          top: -10px;
          &.delete{
            opacity: 0.5;
          }
          &.create{
            width: 350px;
          }
          &.task{
            .text_line{
              padding-top: 10px;
              white-space: pre-wrap;
              text-overflow: ellipsis;
              overflow: hidden;
              .desc_line{
                color: #0E4D8D;
                font-size: 15px;
                line-height: 20px;
              }
              .content_line{
                padding-top: 2px;
                font-size: 14px;
                color: #555555;
                line-height: 22px;
              }
              .pre{
                width: 100%;
                white-space: pre-line;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .brief_title_group{
                @include flex;
                @include brief_type(template);
                @include brief_type(relevancy);
                @include brief_type(text);
                @include brief_type(number);
                @include brief_type(money);
                @include brief_type(time);
                @include brief_type(formula);
                @include brief_type(attachment);
                @include brief_type(hyperlink);
                @include brief_type(location);
                @include brief_type(picture);
                @include brief_type(contact);
                font-size: 14px;
                align-items: flex-start;
                &.brief_action_create{
                  @include brief_action(create);
                }
                &.brief_action_update{
                  @include brief_action(update);
                }
                &.brief_action_delete{
                  @include brief_action(delete);
                }
                &:hover{
                  text-decoration: underline;
                }
                .icon{
                  width: 17px;
                  height: 17px;
                  margin-right: 4px;
                  flex-shrink: 0;
                  margin-top:2px;
                }
                .update_action{
                  padding-right: 4px;
                  flex-shrink: 0;
                }
                .update_type{
                  padding-right: 4px;
                  flex-shrink: 0;
                }
                .update_title{
                  padding-right: 4px;
                  color: #333;
                }
              }
              // .change_title{
              //   padding: {
              //     // left: 21px;
              //     // top: 5px;
              //     // bottom: 5px;
              //   }
              //   font-size: 14px;
              //   color: #555;
              // }
              .brief_content_group{
                @include multi_line_intercept(2);
                max-width: 650px;
                padding: {
                  left: 21px;
                  top: 6px;
                }
              }
              &:hover{
                // text-decoration: underline;
              }
              &:first-child{
                padding-top:0;
              }
            }
          }
          &.file{
            @include flex;
            align-items: stretch;
            &.send{
              .name_group{
                color:#fff;
              }
            }
            .name_group{
              @include flexsb;
              flex-direction: column;
              align-items: flex-start;
              padding-right: 30px;
              color:rgba(51,51,51,1);
              .name{
                font-size:14px;
                
                font-weight:400;
                line-height:20px;
              }
              .size{
                font-size:12px;
                
                font-weight:400;
                line-height:20px;
              }
            }
            .img{
              display: block;
              width: 50px;
              height: 50px;
            }
          }
          .tag_group{
            position: absolute;
            top: 0;
            z-index: 3;
            width: 36px;
            height:36px;
            background: {
              image: url(~@/assets/images/dialog/receive_tag.png);
              size: 100%;
            }
            .tag{
              position: absolute;
              top:3px;
             
              font-size:14px;
              
              font-weight:400;
              color:rgba(255,255,255,1);
              line-height:18px;
            }
          }
          &::before{
            content:'';
            display: block;
            display: none;
            top:0;
            width: 5px;
            height: 7px;
            position: absolute;
            background: {
              image: url(~@/assets/images/dialog/receive_arrow.png);
              size: 100%;
            }
          }
          .more_brief{
            font-size: 14px;
            color:#555;
          }
          .switch_brief{
            color: $main_blue;
            font-size: 14px;
            padding-top: 6px;
          }
        }
        .username{
          font-size:12px;
          position: relative;
          font-weight:400;
          color:rgba(153,153,153,1);
          padding-bottom: 7px;
          height: 27px;
          line-height: 20px;
          word-break: keep-all;
          &.open_at{
            &:hover{
              .at{
                transform: scaleX(1);
                opacity: 1;
              }
              .text{
                opacity: 0;
                transform: scaleX(0)
              }
            }
          }
          .at{
            transform-origin: left center;
            transform: scaleX(0);
            opacity: 0;
            position: absolute;
            top:0;
            left:0;
            white-space: nowrap
          }
          .text{
            white-space: nowrap;
            transform-origin: right center;
            transform: scaleX(1);
            opacity: 1;
            position: absolute;
            top:0;
            left:0;
          }
        }
        &:hover{
          .desc_group{
            .reply{
              opacity: 1;
            }
          }
        }
        .desc_group{
          @include bbox;
          @include transition;
          background: #0054A7;
          border-radius: 4px 4px 0 0;
          padding: 10px 10px 20px;
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 21px;
          position: relative;
          z-index: 1;
          &::after{
            @include background();
            content: '';
            display: block;
            width: 7px;
            height: 13px;
            position: absolute;
            top: 10px;
          }
          &.create{
            width: 350px;
          }
          &:hover{
            // text-decoration: underline;
          }
          &.receive{
            background-color: #E2EEFA;
            color: $main_blue;
            &::after{
              left: -7px;
              background: {
                image: url(~@/assets/images/dialog/bubble_arrow_receive.png);
              }
            }
          }
          &.send{
            &::after{
              right: -7px;
              background: {
                image: url(~@/assets/images/dialog/bubble_arrow_send.png);
              }
            }
          }
          .reply{
            @include transition;
            position: absolute;
            opacity: 0;
            position: absolute;
            top: 10px;
            font-size: 12px;
            width: 30px;
            text-align: center;
            color: #0054A6;
            font-weight: normal;
            &.send{
              left: -35px;
            }
            &.receive{
              right: -35px;
            }
          }
        }
        .tag_group{
          @include flexsb;
          width: 100%;
          padding-top: 4px;
          .tag_box{
            @include flex;
            .tag{
              @include bbox;
              padding: 0 7px;
              background: rgba(255, 114, 0, 0.08);
              border-radius: 4px;
              font-size: 12px;
              color: #FF9D4D;
              line-height: 24px;
              margin-right: 10px;
              &.privacy{
                color: #8C73F1;
                background-color: rgba(83, 47, 230, 0.08);
              }
              &.reminder{
                width: 20px;
                height: 20px;
                padding: 0;
                background-image: url(~@/assets/images/task/task_thumbnail_reminder.png);
              }
            }
          }
          .attended_group{
            margin-left: 20px;
            @include flex;
            .icon{
              @include background;
              width: 15px;
              height: 15px;
              background-image: url(~@/assets/images/dialog/attended.png);
            }
            .text{
              padding-left: 6px;
              font-size: 12px;
              color: #0054A7;
              opacity: 0.5;
            }
          }
        }
      }
    }
    .placeholder{
      width: 44+15px;
      flex-shrink: 0;
    }
  }
}
</style>