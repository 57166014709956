import 'babel-polyfill';
import '@/style/reset.css';
import '@/style/common/margin.scss';
import '@/style/common/padding.scss';
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import VModal from 'vue-js-modal'
// import "element-ui/lib/theme-chalk/index.css";
import 'overlayscrollbars/css/OverlayScrollbars.css';
import '@/style/common/element-variables.scss';
import request from "./api";
import tools from "@/api/tools";
import Toast from '@/utils/toast.js';
import Notice from '@/utils/plugin/notice.js';
import ReminderNotice from '@/utils/plugin/reminderNotice.js';
import Loading from '@/utils/loading.js';
import Confirm from '@/utils/confirm.js';
import Prompt from '@/utils/prompt.js';
import PreviewPicture from '@/utils/plugin/previewPicture.js';
import MessageBox from '@/utils/messageBox.js';
// NPM
import VueClipboard from 'vue-clipboard2';
import VueCookies from 'vue-cookies';
import moment from 'moment'; // 处理时间戳
import VueScrollTo from 'vue-scrollto';
import api from '@/api/api';
import WebIM from '@/utils/WebIM/WebIM';
import filter from '@/utils/filter'; //过滤器
import axios from "axios";
import md5 from 'js-md5';
import {VueMasonryPlugin} from 'vue-masonry'; //瀑布流
import Directives from '@/utils/directives.js'; // 全局自定义指令
import uploader from 'vue-simple-uploader'; // todo [测试]大文件分片上传
import REGEXP from '@/utils/regexp';
import GLOBAL from '@/api/global';
import Video from '@/utils/plugin/video';
import utils_api from '@/api/utils';
import expand from '@/api/expand';
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue'; //滚动条
import oss from '@/utils/oss';
import utils_notify from '@/utils/system/notify';
import UserDetail from '@/utils/plugin/userDetail';
import BecomeMember from '@/utils/plugin/modules/become_member';
import PaymentStatus from '@/utils/plugin/modules/payment_status';
import ConsultingForm from '@/utils/plugin/modules/consulting_form';
// Plugin自定义
import '@/utils/plugin/index'
import BuyNow from '@/utils/plugin/modules/buy_now';
import ScanCode from '@/utils/plugin/modules/scan_code';
import ViewKeys from '@/utils/plugin/modules/view_keys';
import SelectWidgetContact from '@/utils/plugin/modules/select_widget_contact';
import AddToCustomList from '@/utils/plugin/modules/add_to_custom_list';
import Task from '@/utils/plugin/modules/task';

// Utils
import utils_clipboard from '@/utils/clipboard';
import utils_types from '@/utils/types';
import appData from '@/utils/appData'

import apiPlugin from '@/api/plugin/index';



Vue.use(OverlayScrollbarsPlugin);

Vue.use(VueMasonryPlugin)
Vue.use(VueClipboard);
Vue.use(VueScrollTo);
Vue.use(VueCookies);
Vue.use(uploader);
Vue.use(VModal)



/**
 * 
 * this.$task();
 * this.$closeTask();
 */
Vue.use(Task);
/**
 * this.$add_to_custom_list();
 * this.$close_add_to_custom_list();
 */
Vue.use(AddToCustomList);
/**
 * 选择组件用户
 */
Vue.use(SelectWidgetContact);
/**
 * 查看激活码
 * this.$view_keys(params);
 * this.$close_view_keys();
 * @params {Object} {title: '标题'}
 */
Vue.use(ViewKeys);
/**
 * 扫码支付
 * this.$scan_code(params);
 * this.$close_scan_code();
 * @params {Object} {title: '标题'}
 */
Vue.use(ScanCode);
/**
 * 立即购买
 * this.$buy_now(params);
 * this.$close_buy_now();
 * @params {Object} {title: '标题', task: PTask}
 */
Vue.use(BuyNow);
/**
 * 咨询表单
 * this.$consulting_form(params);
 */
Vue.use(ConsultingForm);
/**
 * 支付状态
 * this.$payment_status(params);
 * this.$close_payment_status();
 */
Vue.use(PaymentStatus);
/**
 * 会员弹层
 * this.$become_member({title?})
 * this.$close_become_member();
 */
Vue.use(BecomeMember);

/**
 * this.$user_detail({userId: ''});
 */
Vue.use(UserDetail);
/**
 * this.$reminder_notice();
 */
Vue.use(ReminderNotice); // 提醒通知

/**
 * this.$preivewPicture({url, list})
 */
Vue.use(PreviewPicture); // 预览图片
/**
 * Notice示例:
 * this.$notice({desc: '描述', type: '类型', duration: '持续时间', mask: '遮罩层'})
 */
Vue.use(Notice);
/**
 * Video 示例:
 */
Vue.use(Video);
/**
 * Loading
 * background暂无效
 * this.$showLoading({text:'', background:''})
 * this.$hideLoading();
 */
Vue.use(Loading);
/**
 * Confirm
    this.$openConfirm({
      title:'',
      desc:'',
      sure:'',
      sureType: '', // 确定按钮样式 '','warn'
      cancel:'',
      onSure:()=>{},
      onCancel:()=>{},
    })
 * 标题 / 描述 / 确认文字 / 确定按钮样式 / 取消文字 / 确认回调 / 取消回调
 */
Vue.use(Confirm);

/**
 * Prompt
    this.$openPrompt({
      title:'',
      placeholder: '',
      hint: '未输入内容时的提示内容',
      sure: '',
      cancel: '',
      onSure: ()=>{},
      onCancel: ()=>{},
    })
 */
Vue.use(Prompt);
/**
 * MessageBox
 * this.$openMessageBox({title:'',desc:'',sure:'',onSure:function,})
 * 标题 / 描述 / 确认文字 / 取消文字 / 确认回调 / 取消回调
 */
Vue.use(MessageBox);
// Toast 
// this.$toast({icon:'success/none',title:'',desc:'',duration:2000});
Vue.use(Toast);
// import request from "./req";
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$http = request;
Vue.prototype.$api = api;
Vue.prototype.$global = GLOBAL;
Vue.prototype.$axios = axios;
Vue.prototype.$tools = tools;
Vue.prototype.$md5 = md5;
Vue.prototype.$moment = moment;
Vue.prototype.$domain_name = process.env.VUE_APP_DOMAIN_NAME; //域名
Vue.prototype.$cm_id = 5494; // 小妙id
Vue.prototype.$regexp = REGEXP;
Vue.prototype.$feature = true; // feature分支开发
Vue.prototype.$utils = utils_api; // utils api
Vue.prototype.$expand = expand; // 拓展
Vue.prototype.$dev_sign = 1; // 开发标记
Vue.prototype.$oss = oss;
Vue.prototype.$utils_notify = utils_notify;
Vue.prototype.$plugin = apiPlugin;
Vue.prototype.$critical = Number(moment('2021-09-08 10:56').format('x'));
Vue.prototype.$picture_critical = Number(moment('2021-09-17 11:00').format('x'));
Vue.prototype.$version = process.env.VUE_APP_Version;
// utils
Vue.prototype.$types = utils_types;
Vue.prototype.$clipboard = utils_clipboard
Vue.prototype.$appData = appData

// 处理时间戳 | 全局过滤
Vue.filter('formatDate',function(value, formatStr='YYYY/MM/DD HH:mm'){
  return moment(value).format(formatStr);
})
/**
 * 遍历所有过滤器
 */
for(let item in filter){
  Vue.filter(item, filter[item]);
}
// 定义全局点击函数
Vue.prototype.globalClick = function (callback) {
  document.getElementById('app').onclick = function () {
      callback();
  };
};
/**
 * 前缀
 */
Vue.prototype.$default_avatar = `images/common/avatar_default.png`;
// 头像
Vue.prototype.$avatar_url = 'https://img-icon.ourutec.com/';
// 图片
Vue.prototype.$img_url = 'https://img-content.ourutec.com/';
// 文件
Vue.prototype.$file_url = 'https://mixture-attach.ourutec.com/';
// 视频
Vue.prototype.$video_url = 'https://video.ourutec.com/';
// http 
Vue.prototype.$file_url_http = 'http://mixture-attach.ourutec.com/';
// 上传文件限制类型
Vue.prototype.$input_file_accept = '.png,.jpg,.jpeg,.gif,.mp3,.rar,.zip,.tar,.gz,.7z,.bz2,.iso,.xls,.xlsx,.pdf,.doc,.docx,.txt,.wps,.xps,.ppt,.pptx,.xmind,.ps,.psd,.exe,.heic,.aov,';
// Vue.prototype.$input_file_accept = '*';
// 图片
Vue.prototype.$input_file_accept += '.webp,';
// 上传文件限制类型 视频
Vue.prototype.$input_file_accept += '.mp4,.flv,.f4v,.webm,.m4v,.mov,.3gp,.3g2,.rm,.rmvb,.wmv,.avi,.asf,.mpg,.mpeg,.mpe,.ts,.div,.dv,.divx,.vob,.dat,.mkv,.swf,.lavf,.cpk,.dirac,.ram,.qt,.fli,.flc,.mod';

/**
 * 控件保留字段
 */
Vue.prototype.$common_reserved_fields = [
  'type',
  'sort',
  'id',
  'taskId',
  'taskDetailNext',
  'islock',
]

// 自定义指令
if(Directives){
  for(let i in Directives){
    // // console.log(i, Directives[i])
    Vue.directive(i, Directives[i]);
  }
}
const vue = new Vue({
  el: "#app",
  router,
  store,
  WebIM,
  // comments: { App },
  template:'<App/>',
  render: h => h(App)
}).$mount("#app");

export default vue;
