import vue from '@/main';
import store from '@/store'
import tools from '@/api/tools.js'
import {PFolder} from '@/types'
class PTaskLog{
  // chatId = undefined
  // createTime = undefined
  // deleteFlag = undefined
  // details = undefined
  // flags = undefined
  // fromUserId = undefined
  // hide = undefined
  // id = undefined
  // isremind = undefined
  // linkme = undefined
  // linkusers = undefined
  // logType = undefined
  // shareType = undefined
  // shareUrl = undefined
  // taskId = undefined
  // taskName = undefined
  // taskType = undefined
  // thumbnail = undefined
  // toUserId = undefined
  // tousers = undefined
  // type = undefined
  // userName = undefined
  // work = undefined
  // cf = undefined
  constructor(params){
    const {
      chatId,
      createTime,
      deleteFlag,
      details,
      flags,
      fromUserId,
      hide,
      id,
      isremind,
      linkme,
      linkusers,
      logType,
      shareType,
      shareUrl,
      taskId,
      taskName,
      taskType,
      thumbnail,
      toUserId,
      tousers,
      type,
      userName,
      work,
      cf,
      levelStatus,
      processStatus,
      wide,
      high,
    } = params || {}
    this.id = id; //	String		id
    this.taskId = taskId; //	String		任务id
    this.work = work; //	String		操作记录
    this.createTime = createTime; //	String		时间
    this.fromUserId = fromUserId; //	String		操作人
    this.toUserId = toUserId; //	String		接收人
    this.type = type; //	String		0单聊 1群聊
    this.chatId = chatId; //	String		群聊id
    this.taskName = taskName; //	String		任务单名称
    this.linkme = linkme; //	Boolean		true与我有关 false 无关
    this.details = details; //	List		任务单记录明细
    this.taskType = taskType; //	String		1普通模板 2教学范文 3教学模板 4范文购买留存
    this.deleteFlag = deleteFlag; //			0正常  1模板已删除
    this.logType = logType; //	String		0模板1分享2工作组变更3模板消息 4工作组/用户非模板消息
    this.shareType = shareType; //	String		分享类型 1模板 2教学包 3h5
    this.hide = hide; //	Int		0公开 1隐私
    this.flags = flags;
    this.isremind = isremind;
    this.shareUrl = shareUrl;
    // this.taskusers = taskusers;
    this.thumbnail = thumbnail;
    this.userName = userName;
    this.tousers = tousers
    this.cf = cf
    this.linkusers = linkusers
    this.levelStatus = (levelStatus ?? '') === '' ? levelStatus : String(levelStatus)
    this.processStatus = (processStatus ?? '') === '' ? processStatus : String(processStatus)
    this.wide = wide
    this.high = high
  }
  
  /**
   * 查看[任务]消息上下文
   */
  checkTaskMessageContext=()=>{
    const {taskId, id} = this;
    vue.$api.moduleTask.openTask({taskId, config: {
      chat: true,
      chat_message_id: id,
      edit: true,
    }})
  }

  folderPreview = ()=>{
    store.commit('set_collect_nav', undefined)
    tools.removeCookie('collect_nav')
    const {taskId: id,} = this
    store.commit('set_collect_config', Object.assign(
      {},
      {
        preview: true,
      }
    ))
    vue.$folderPreview({
      id,
    })
  }

}

export default PTaskLog;