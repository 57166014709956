<template>
    <div class="forget-conr">
        <div class="forget-right">
            <!-- <p class="text">请输入手机号码</p> -->
            <div class="i-box">
                <img src="../../../assets/icon-mobile.png" alt class="img2" />
                <input class="phone" type="text"
                  v-model="userInfo.loginName"
                  maxlength="11"
                  placeholder="请输入手机号码"
                />
                <img src="../../../assets/icon-shut.png" alt class="shut" @click="shut" v-show="userInfo.loginName"/>
            </div>
            <div class="line"></div>
            <!-- <p class="text">请输入图形验证码</p> -->
            <div class="ma">
                <img src="../../../assets/icon-Captcha.png" alt class="captcha" />
                <input class="img-check" type="text" v-model="userInfo.tuyzm"
                  placeholder="请输入图形验证码"
                />
                <img :src="execute" @click="getExecute" alt="" class="graphical">
            </div>
            <div class="line"></div>
            <!-- <p class="text">请输入短信验证码</p> -->
            <div class="i-box">
                <img src="../../../assets/icon-message.png" class="img2" alt />
                <input class="i-short" type="text" v-model="notecode"
                  placeholder="请输入短信验证码"
                />
                <el-col :span="10" style="margin-right: -2px;">
                    <el-button class="short" @click="sendcodeMsg" :style="disabled?'borderColor:grey':'borderColor:#0054A6'" :disabled="disabled">{{sendcode}}
                    </el-button>
                </el-col>
            </div>
            <div class="line1"></div>
            <p v-html="userNameWarn" class="cuowu">请输入</p>
            <div class="box">
                <el-button class="login_btn" @click.native="login" type="primary" round :loading="isBtnLoading">找回密码
                </el-button>
                <el-button class="forgetps_btn" @click.native="returnLogin" type="primary" round :loading="isBtnLoading">返回登录</el-button>
            </div>
            <div class="rudeu">
                <p class="automatic" v-show="0">30天内自动登录</p>
                <p class="letgo">
                    <!-- 没有账号去 -->
                    <a @click="zhuce" class="cp">立即注册</a>
                </p>
                <template v-if="0">
                  <img v-if="nor" @click="norc" src="../../../assets/icon-nor.png" alt class="nor"/>
                  <img v-else style="left:-311px" @click="norc" src="../../../assets/icon-dui.png" alt class="nor" />
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            userInfo: {
                loginName: '',
                type: "2",
                flag: "1",
                tuyzm: ""
            },
            nor: true,
            sendcode: "获取验证码",
            isBtnLoading: false,
            sel: true,
            userNameWarn: "",
            disabled: false,
            execute: '', // 图片验证码地址
            show:false,
            notecode:"",
            verifyStamp: '',
        };
    },
    computed: {
        btnText() {
            if (this.isBtnLoading) return "登录中...";
            return "登录";
        }
    },
    watch: {
      //只能输入数字
      'userInfo.loginName': function(val){
        if(val){
          val = val
            .replace(/[^\d]+/g, "")
            // .replace(".", "$#$")
            // .replace(/\./g, "")
            // .replace("$#$", ".");
          this.$set(this.userInfo, 'loginName', val);
        }
      }
    },
    mounted() {
        this.getExecute()
    },
    methods: {
      /**
       * 忘记密码
       */
        async login() {
            if(!this.userInfo.loginName){
              this.userNameWarn = '请输入手机号';
              return;
            }
            if(!this.notecode){
              this.userNameWarn = '请输入正确的验证码';
              return;
            }

            this.userNameWarn = ''; // 清空提示

            let res = await this.$http.getConfirmAuthCode({
                "loginName":this.userInfo.loginName,
                "authCode":this.notecode
            })
            if(res.message == 'success'){
              this.nextStep()
            }else{
              this.userNameWarn = '请输入正确的验证码';
              this.$message(res.describe)
            }
        },
        nextStep(){
            if (this.show && this.notecode.length != "0") {
                if (this.nor === false) {
                    var time = new Date();
                    time.setTime(time.getTime() + 1000 * 60 * 60 * 24 * 30);
                    document.cookie = `miaohangUsername=${this.userInfo.loginName};expires=${time}`;
                }else{
                    document.cookie = `miaohangUsername=${this.userInfo.loginName}`;
                }
                this.userNameWarn = "";
                this.$emit("findPassword", false, this.userInfo.loginName, this.nor, this.notecode);
            } else if(this.show) {
                this.userNameWarn = "*请输入短信验证码";
            }else{
                this.userNameWarn = "*请获取短信验证码";
            }
        },
        returnLogin() {
            this.$router.back();
        },
        shut() {
            this.userInfo.loginName = "";
        },
        norc() {
            this.$data.nor = !this.$data.nor;
        },
        getExecute() { // 获取图形验证码
          let verfiyStamp = String(new Date().getTime()) + Math.ceil(Math.random()*1000);
          this.verifyStamp = verfiyStamp;
          this.execute = `https://${this.$domain_name}/verify/execute.htm?t=${verfiyStamp}`
        },
        // 发送验证码
        async sendcodeMsg() {
            let _this = this;
            let params = this.userInfo;
            // console.log(params, 'params');

            const userReg = /^1[3456789]\d{9}$/;
            if (userReg.test(this.userInfo.loginName) && params.loginName && params.tuyzm) {
              this.userNameWarn = '';
              /**校验图形验证码 */
              let verifyRes = await this.$http.request('/verify/executeVerify',{t:this.verifyStamp, tucode: this.userInfo.tuyzm});
              if(verifyRes.message != 'success'){
                // this.$toast({icon:'none',title:'错误提示',desc:verifyRes.describe,duration:1000});
                this.userNameWarn = '请输入正确的图形验证码';
                return;
              }
              const res = await this.$http.getSendAuthcode(params);
              if(res.message == 'success'){
                this.show = true;
                var num = 60;
                var timer = setInterval(function () {
                    num--;
                    _this.disabled = true;
                    _this.sendcode = `已发送(${num}s)`;
                    if (num === 0) {
                        _this.sendcode = "获取验证码";
                        _this.disabled = false;
                        clearInterval(timer);
                    }
                }, 1000);
              }else{
                // this.telWarn = res.describe;
                this.$message( res.describe);
              }

            }else if(userReg.test(this.userInfo.loginName) == false && this.userInfo.loginName.length != "0"){
                this.userNameWarn = "*请输入正确的手机号"
            } else {
                this.userNameWarn = "*手机号和图片验证码不能为空";
            }
        },
        zhuce() {
            this.$router.push("/login/register")
        }
    }
}
</script>
<style lang="less" scoped>
.forget-conr {
    width: 724px;
    height: 650px;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    .forget-right {
        position: relative;
        width: 556px;
        .text {
            margin-top: 50px;
            width: 144px;
            height: 17px;
            font-size: 16px;

            font-weight: 400;
            color: rgba(130, 146, 156, 1);
            margin-bottom: 10px;
        }

        .i-box {
            display: flex;
            position: relative;
            align-items: center;
            padding-top: 60px;
            input {
                outline: none;
                display: block;
                width: 190px;
                height: 30px;
                font-size: 18px;

                font-weight: 400;
                border: none;
                color: rgba(130, 146, 156, 1);
                margin-left: 10px;
            }

            .i-short {
                // margin-top: 5px;
            }

            .img2 {
                display: flex;
                width: 18px;
                height: 22px;
                // margin-top: 10px;
                // margin-bottom: 5px;
            }

            .shut {
                width: 18px;
                height: 12px;
                position: absolute;
                bottom: 8px;
                left: 308px;
            }

            .sel {
                width: 18px;
                height: 12px;
                position: absolute;
                left: 308px;
                top: 13px;
            }

            .password {
                // margin-top: 5px;
            }

            .short {
                width: 110px;
                height: 40px;
                // margin-bottom: 4px;
                border-radius: 4px;
                border: none;
                border: 2px solid #0054a6;
                background-color: #fff;
            }
        }

        .ma {
            position: relative;
            display: flex;
            width: 330px;
            align-items: center;
            // height: 42px;
            padding-top: 60px;
            .img-check {
                width: 153px;
                height: 40px;
                margin-left: 10px;
                border: none;
                outline: none;
                font-size: 18px;
            }
            .captcha {
                width: 18px;
                height: 22px;
                // margin-top: 10px;
            }

            .graphical {
                position: absolute;
                cursor: pointer;
                bottom: 2px;
                width: 110px;
                height: 40px;
                margin-left: 39px;
                border-radius: 4px;
                border: none;
                // border: 2px solid #0054a6;
                background-color: #fff;
                right: 0;
            }
        }

        .graphical {
            width: 110px;
            height: 40px;
            margin-left: 39px;
            border-radius: 4px;
            border: none;
            border: 2px solid #0054a6;
            // margin-bottom: 6px;
        }

        .short {
            width: 110px;
            height: 40px;
            margin-bottom: 4px;
            border-radius: 4px;
            border: none;
            border: 2px solid #0054a6;
        }

        .line {
            width: 330px;
            height: 1px;
            background: rgba(213, 217, 219, 1);
        }

        .line1 {
            width: 330px;
            height: 1px;
            background: rgba(213, 217, 219, 1);
        }

        .cuowu {
            display: block;
            width: 220px;
            height: 15px;
            font-size: 14px;
            color: #d82e0d;
            margin-top: 15px;
        }

        .box {
            margin-top: 50px;
            width: 330px;
            display: flex;
            justify-content: space-between;
            .login_btn {
                width: 160px;
                height: 50px;
                background: rgba(0, 84, 166, 1);
                border-radius: 4px;
                border: none;
                font-size: 18px;
                // margin-right: 10px;
            }

            .login_btn:hover {
               background: #004080;
               color: rgba(255, 255, 255, 1);
            }

            .forgetps_btn {
                width: 160px;
                height: 50px;
                background: #e5eef6;
                border-radius: 4px;
                color: rgba(0, 84, 166, 1);
                font-size: 18px;
                border: none
            }

            .forgetps_btn:hover {
                background: #ccdded;
            }
        }

        .rudeu {
            // width: 556px;
            width: 330px;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            // position: relative;

            .nor {
                position: relative;
                left: -311px;
                margin-top: 2px;
            }

            .sex {
                width: 16px;
                height: 16px;
                margin-top: 2px;
                // border-radius:50%  ;
            }

            .automatic {
                margin-left: 25px;
                width: 114px;
                height: 15px;
                font-size: 14px;

                font-weight: 400;
                color: rgba(130, 146, 156, 1);
            }

            .letgo {
                margin-left: 56px;
                // width: 117px;
                // height: 17px;
                font-size: 14px;

                font-weight: 400;
                text-decoration: underline;
                color: rgba(130, 146, 156, 1);
                // text-align: center;

                a {
                    color: #0054a6;
                }
            }
        }
    }
}
</style>
