<!--
 * @Author       : Hugo
 * @Date         : 2022-04-14 10:18:24
 * @LastEditTime: 2022-05-19 15:43:37
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/Common/Modules/Thumb/Task.vue
 * @Description  : 任务缩略
 * @^_^          : 
-->

<template>
  <div class="c_common_modules_thumb_task">
    <div class="container flex">
      <div class="select_group"
        v-if="switch_select"
      >
        <div class="icon cp"
          @click.stop="doSelect()"
          :class="{on: p_task.cf && p_task.cf.selected}"
        ></div>
      </div>
      <div class="task_group flexsb cp"
        @click="handleClick()"
      >
        <div class="name_group flexsb">
          <!--<div class="name">{{p_task.name}}</div>-->
          <div class="name">
            <title-status-tag
              class="status_tag_group"
              :level-status="String(p_task.levelStatus)"
              :process-status="String(p_task.processStatus)"
              size="14px"
            ></title-status-tag>
            <span class="task_name">
              {{ p_task.name }}
            </span>
          </div>
          <el-popover
            placement="right-start"
            width="400"
            trigger="click"
            :visible-arrow="false"
            popper-class="c_collect_folder_unit_popover"
            v-model="switch_popover"
            @hide="hidePopoverHandler()"
            @show="popoverShowHandler()"
          > 
            <!-- 自定义文件夹菜单 -->
            <div class="menu_group"
              v-if="!start_moving"
              ref="menu_group"
            >
              <div class="menu_list_group">
                <div
                  v-if="canSetStatus"
                  class="item un_sel cp"
                  @click="switch_more_btn=false;touchSetStatusTag();"
                >
                  <div
                    class="text"
                  >
                    设置状态
                  </div>
                </div>
                <div
                  v-if="canSetGeneralTag"
                  class="item un_sel cp"
                  @click="stouchSetGeneralTag()"
                >
                  <div
                    class="text"
                  >
                    设置标签
                  </div>
                </div>
                <div class="item un_sel cp"
                  @click="startMove();"
                  v-if="moveable"
                >
                  <img src="~@/assets/images/custom/menu/move.png" alt="" class="icon">
                  <div class="text">移动至</div>
                  <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow">
                </div>
                <div class="item un_sel cp"
                  v-if="collectable"
                  @click="touchCollect()"
                >
                  <img src="~@/assets/images/custom/menu/move_out.png" alt="" class="icon">
                  <div class="text"
                    v-text="p_task.collectionflag?'取消收藏':'收藏'"
                  >取消收藏</div>
                </div>
                <div class="item un_sel cp"
                  @click="startSelect();"
                  v-if="!disable_select && multipleable"
                >
                  <img src="~@/assets/images/custom/menu/multiple.png" alt="" class="icon">
                  <div class="text">多选任务</div>
                </div>
                <div class="item un_sel cp"
                  @click="callTask()"
                >
                  <img src="~@/assets/images/custom/menu/call.png" alt="" class="icon">
                  <div class="text">调用任务</div>
                </div>
                <div class="item un_sel cp"
                  @click="shareTask()"
                >
                  <img src="~@/assets/images/custom/menu/share.png" alt="" class="icon">
                  <div class="text">分享任务</div>
                </div>
              </div>
            </div>
            <div class="moving_path_wrapper"
              v-if="switch_popover && start_moving"
              :style="{width: `${moving_path_width}px`, 'z-index': moving_path_z_index}"
            >
              <moving-path
                v-if="switch_popover && start_moving"
                :mask="false"
                :multiple="false"
                :current_folder="current_folder"
                type="file"
                :id="String(p_task.id)"
                :max_height="String(moving_max_height)"
                @handleRemove="handleRemove"
                @handleSure="handleSure"
              ></moving-path>
            </div>
            <button class="more_btn button un_sel" slot="reference"
              v-show="menu"
              @click.stop="$api.stopPropagation($event);"
            ></button>
          </el-popover>
          
        </div>
        <div class="time_group"
          v-if="show_time"
        >
          <div class="time">{{p_task.updateTime | universalDate}}</div>
        </div>
        <img 
          v-if="is_shopping_mail && is_teach"
          src="~@/assets/images/shopping_mail/teaching_task.png" alt="" 
          class="shopping_mail_teaching"
        >
      </div>
      <div class="info_group flexsb">
        <div
          v-if="p_task.createuserthumbnail"
          class="avatar_group"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="p_task.createusername"
            placement="top-start"
            :open-delay="500"
          >
            <img 
              :src="`${$avatar_url}${p_task.createuserthumbnail}@!small200`"
              alt=""
              class="avatar"
              v-if="show_user"
            >
          </el-tooltip>
        </div>
        <div
          v-else
        ></div>
        <div class="tag_group flex">
          <div
            v-if="0"
            class="tag finance"
          >
            财
          </div>
          <template
            v-if="show_mini_tag"
          >
            <div class="tag teach" v-if="is_teach">教</div>
          </template>
        </div>
      </div>

    </div>
    <div class="from_group flex"
      v-if="show_from"
    >
      <div class="text">来源:</div>
      <el-tooltip
        class="item"
        effect="dark"
        :content="p_task.from"
        placement="bottom-start"
        :open-delay="500"
      >
        <div class="name">{{p_task.from}}</div>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import store from '@/store'
import tools from '@/api/tools'
import MovingPath from '@/components/Common/MovingPath/Container.vue';
import TitleStatusTag from '@/components/Task/Main/LineBar/TaskTitle/TitleStatusTag'
export default {
  components: {
    MovingPath,
    TitleStatusTag
  },
  props: {
    p_task: {
      type: Object,
      default: undefined,
    },
    menu: { // 开启菜单
      type: Boolean,
      default: false,
    },
    switch_select: {
      type: Boolean,
      default: false,
    },
    disable_select: {
      type: Boolean,
      default: false,
    },
    current_folder: {
      type: Object,
      default: undefined,
    },
    show_from: {
      type: Boolean,
      default: true
    },
    show_time: {
      type: Boolean,
      default: true
    },
    show_user: {
      type: Boolean,
      default: true
    },
    show_mini_tag: {
      type: Boolean,
      default: true
    },
    type: { // 类型
      type: String,
      default: 'default' // 'shopping-mail' 商城
    },
    canSetStatus: { // 是否可以设置状态标签
      type: Boolean,
      default: false
    },
    canSetGeneralTag: { // 是否可以设置普通标签
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      start_moving: false,
      switch_popover: false,
      moving_path_width: 0,
      moving_path_z_index: 0,
      moving_max_height: '451',
    }
  },
  computed:{
    is_shopping_mail(){
      console.log(this.type, this.type === 'shopping-mail')
      return this.type === 'shopping-mail'
    },
    collectable(){
      const {current_folder} = this
      const user_id = tools.getUserId()
      if((current_folder??'') === ''){
        return false
      }
      const self = Number(current_folder.createuserid) === Number(user_id)
      if(self || current_folder.id === 0){
        return true
      }

      return false
    },
    
    multipleable(){
      const {current_folder} = this
      const user_id = tools.getUserId()
      if((current_folder??'') === ''){
        return false
      }
      const self = Number(current_folder.createuserid) === Number(user_id)
      if(self || current_folder.id === 0){
        return true
      }

      return false
    },

    moveable(){
      const {current_folder} = this
      const user_id = tools.getUserId()
      if((current_folder??'') === ''){
        return false
      }
      const self = Number(current_folder.createuserid) === Number(user_id)
      if(self || current_folder.id === 0){
        return true
      }

      return false
    },
    // ******** VUEX
    switch_moving_path(){
      return store.state.switch_moving_path;
    },
    close_task_menu_of_moving(){
      return store.state.close_task_menu_of_moving;
    },
    // ********  IS
    is_teach(){
      const {p_task} = this
      const {taskType} = p_task
      if(taskType == 2 || taskType == 3){
        return true
      }
      return false
    },
    // 是否作者
    is_sign(){
      const userid = this.$tools.getUserId();
      const {p_task} = this;
      const {signUserId} = p_task;
      return userid == signUserId;
    },
    // 是否创建者
    is_created_user(){
      const userid = this.$tools.getUserId();
      const {p_task} = this;
      const {userId} = p_task;
      if(userId == userid){
        return true;
      }
      return false;
    },
    // ********  STATUS
    // 是否显示头像
    // status_avatar(){
    //   const {p_task, is_sign} = this;
    //   const {taskType} = p_task;
    //   if(!is_sign && taskType == 2){
    //     return false;
    //   }
    //   return true;
    // },
    // 自定义菜单
    status_custom_menu(){
      const {type} = this;
      if(type == 'custom'){
        return true;
      }
      return false

    },
    // 菜单按钮
    status_menu_btn(){
      const {type, open_selected} = this;
      // console.log( open_selected)
      if(type != 'custom'){
        return false;
      }
      if(open_selected){
        return false;
      }
      return true;
    }
  },
  watch: {
    close_task_menu_of_moving: function(val){
      if(val){
        this.doCloseMenu();
        this.$nextTick(function(){
          store.commit('set_close_task_menu_of_moving', false);
        })
        // console.log(this.switch_popover);
      }
    },
    switch_moving_path: function(val){
      if(val == false){
        this.doCloseMenu();
      }
    }
  },  
  methods: {
    stouchSetGeneralTag() {
      this.doCloseMenu()
    },
    touchSetStatusTag() {
      this.doCloseMenu()
      this.$openSetStatusTag({
        title: '任务状态',
        pTask: this.p_task
      })
    },
    /**
     * @description: 点击钩子
     * @param {*}
     * @return {*}
     */    
    handleClick(){
      this.doCloseMenu()
      const { switch_select } = this
      // 如果是多选状态 则直接出发多选
      if (switch_select) {
        this.doSelect()
        return
      }
      this.$emit('handleClick', this.p_task)
    },
    removeCollect(){
      this.doCloseMenu();
      this.$emit('handleRemoveCollect')
    },
    touchCollect(){
      this.doCloseMenu()
      this.$emit('handleTouchCollect')
    },
    /**
     * @description: 关闭菜单
     * @param {*}
     * @return {*}
     */
    doCloseMenu(){
      this.start_moving = false;
      this.switch_popover = false;
    },
    /**
     * @description: 调用任务
     * @param {*}
     * @return {*}
     */
    callTask(){
      this.$emit('handleCallTask');
      this.doCloseMenu();
    },
    /**
     * @description: 分享任务
     * @param {*}
     * @return {*}
     */
    shareTask(){
      this.$emit('handleShareTask');
      this.doCloseMenu();
    },
    /**
     * @description: 开启多选任务
     * @param {*}
     * @return {*}
     */
    startSelect(){
      this.$emit('handleStartSelect')
      this.doCloseMenu()
    },
    doSelect(){
      const {p_task} = this
      p_task.select()
    },
    handleSure(aim){
      console.log('task', aim)
      this.$emit('handleSure', aim)
    },
    /** 移除当前文件夹 */
    handleRemove(){
      console.log('folderUnit')
      this.$emit('handleRemove')
    },




 
    /**
     * @description: 选择
     * @param {*}
     * @return {*}
     */
    doSelected(){
      this.$parent.doTaskSelected(this.p_task);
    },
   
    /**
     * @description: 移出自定义分组
     * @param {*}
     * @return {*}
     */
    moveOutFromCustom(){
      // const membership_privileges = this.$api.user.membershipPrivileges({hint: '该操作为会员权益，当前用户暂无权限'});
      // if(!membership_privileges){
      //   return ;
      // }
      this.$parent.moveOutFromCustom(this.p_task);
    },

    /**
     * @description: 打开任务
     * @param {*}
     * @return {*}
     */
    openTask(){
      this.$parent.openTask(this.p_task);
    },
 
    
    /**
     * @description: 设置标签
     * @param {*}
     * @return {*}
     */
    setTag(){
      this.$parent.setTag(this.p_task);
    },
    /**
     * @description: 删除任务
     * @param {*}
     * @return {*}
     */
    deleteTask(){
      this.$parent.deleteTask(this.p_task);
    },
    startMove(){
      // console.log('start_moving')
      this.start_moving = true;
      const clientWidth = document.body.clientWidth;
      const clientHeight = document.body.clientHeight;
      const rect = this.$refs.menu_group.getBoundingClientRect();
      const left = rect.left;
      const top = rect.top;
      // 计算记录最右边宽度 有20边距
      const width = clientWidth - left - 20;
      // 计算记录最下边宽度 有20边距
      const height = clientHeight - top -20;
      this.moving_max_height = (height > 451) ? '451' : height;
      this.moving_path_width = width,
      this.moving_path_z_index = this.$utils.getZIndex();
      // this.switch_moving_path = true;
      store.commit('set_switch_moving_path', true);
    },
    /**
     * @description: 隐藏钩子
     * @param {*}
     * @return {*}
     */
    hidePopoverHandler(){
      this.start_moving = false;
    },
    popoverShowHandler(){
      this.setPopoverZ();
    },
    setPopoverZ(){
      this.$nextTick(() => {
        document.querySelectorAll('.c_collect_folder_unit_popover').forEach(item=>{
          item.style.zIndex = this.$utils.getZIndex();
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.c_common_modules_thumb_task{

  .container{
    flex-direction: column;
    width: 160px;
    height: 140px;
    background: #fff;
    border: 1px solid #E8EEFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    position: relative;
    z-index: 2;
  }
  .select_group{
    position: absolute;
    width: 22px;
    height: 22px;
    top:-7px;
    left: -7px;
    .icon{
      @include transition;
      @include background;
      width: 22px;
      height: 22px;
      background-image: url(~@/assets/images/custom/unselected.png);
      &.on{
        background-image: url(~@/assets/images/custom/selected.png);
      }
    }
  }
  .task_group{
    @include bbox;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100px;
    border-bottom: 4px solid #F8F8F8;
    padding: 10px 0;
    .name_group{
      @include bbox;
      position: relative;
      z-index: 3;
      width: 100%;
      align-items: flex-start;
      padding: {
        left: 14px;
        right: 4px;
      }
    }
    &:hover{
      .more_btn{
        opacity: 1;
      }
    }
    .more_btn{
      @include transition;
      @include background(3px, 15px);
      position: relative;
      z-index: 3;
      background-image: url(~@/assets/images/custom/menu_btn.png);
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      opacity: 0;
    }
    .name{
      @include text_justify;
      @include multi_line_intercept(3);
      text-align-last: left;
      width: 100%;
      font-size: 12px;
      color: #333333;
      line-height: 18px;
      padding-right: 10px;
    }
    .time_group{
      padding: 0 14px;
      position: relative;
      z-index: 3;
    }
    .shopping_mail_teaching{
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 0;
      width: 70px;
    }
    .time{
      @include bbox;
      flex-shrink: 0;
      font-size: 12px;
      border-left: 4px solid #E5E5E5;
      padding-left: 7px;
    }
  }  
  .info_group{
    @include bbox;
    padding: 0 14px;
    width: 100%;
    height: 40px;
    align-items: center;
    .avatar_group{
      .avatar{
        width: 26px;
        height: 26px;
        border-radius: 4px;
        overflow: hidden;
      }
    }
    .tag_group{
      .tag{
        font-size: 12px;
        line-height: 18px;
        width: 26px;
        text-align: center;
        border-radius: 2px;
        &.finance {
          color: #D32F2F;
          background-color: #FFEBEE;
        }
        &.teach {
          color: #FF9D4D;
          background-color: rgba(255, 114, 0, 0.08);
        }
      }
    }
  }
  .from_group{
    @include bbox;
    padding: 0 4px;
    overflow: hidden;
    width: 160px;
    font-size: 12px;
    padding-top: 10px;
    .text{
      flex-shrink: 0;
      color:#666;
      padding-right: 5px;
    }
    .name{
      @include single_line_intercept;
      flex: 1;
      width: 100%;
      color: $main_blue;

    }
  }
}

</style>
