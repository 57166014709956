/*
 * @Author      : Hugo
 * @Date        : 2021-11-24 15: 13: 43
 * @LastEditTime: 2022-05-31 11:17:27
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/types/modules/Userinfo/index.js
 * @Description : 
 * @^_^         : 
 */
import vue from '@/main';
class UserInfo{
  address             = undefined
  applyStatus         = undefined
  authStatus          = undefined
  channelName         = undefined
  chatUserName        = undefined
  city                = undefined
  coder               = undefined
  compCheckStatus     = undefined
  compLicensePath     = undefined
  compLinkmanName     = undefined
  compLinkmanPhone    = undefined
  compRegNo           = undefined
  compRunExtent       = undefined
  compScope           = undefined
  compSetUpDate       = undefined
  companyFullname     = undefined
  companyUserId       = undefined
  createTime          = undefined
  email               = undefined
  friendRemark        = undefined
  hxPwd               = undefined
  identity            = undefined
  isfriend            = undefined
  listSkill           = undefined
  loginName           = undefined
  maxUserNums         = undefined
  openId              = undefined
  openproject         = undefined
  ownedEnterprises    = undefined
  personalDescribe    = undefined
  phone               = undefined
  province            = undefined
  provinceCoder       = undefined
  punishNum           = undefined
  sign                = undefined
  spaceBackgroundPath = undefined
  threeOne            = undefined
  thumbnail           = undefined
  updateTime          = undefined
  updateremark        = undefined
  userCardNo          = undefined
  userId              = undefined
  userIntegral        = undefined
  userName            = undefined
  userPassword        = undefined
  userPosition        = undefined
  userRealName        = undefined
  userSex             = undefined
  userStatus          = undefined
  userToken           = undefined
  userType            = undefined
  userVip             = undefined
  usercode            = undefined
  vipDays             = undefined
  vipEndDate          = undefined
  vipStartDate        = undefined
  isgetgift           = undefined
  ischeckphones       = undefined

  // cf
  selected = undefined

  constructor(params={}){
    const {
      address,
      applyStatus,
      authStatus,
      channelName,
      chatUserName,
      city,
      coder,
      compCheckStatus,
      compLicensePath,
      compLinkmanName,
      compLinkmanPhone,
      compRegNo,
      compRunExtent,
      compScope,
      compSetUpDate,
      companyFullname,
      companyUserId,
      createTime,
      email,
      friendRemark,
      hxPwd,
      identity,
      isfriend,
      listSkill,
      loginName,
      maxUserNums,
      openId,
      openproject,
      ownedEnterprises,
      personalDescribe,
      phone,
      province,
      provinceCoder,
      punishNum,
      sign,
      spaceBackgroundPath,
      threeOne,
      thumbnail,
      updateTime,
      updateremark,
      userCardNo,
      userId,
      userIntegral,
      userName,
      userPassword,
      userPosition,
      userRealName,
      userSex,
      userStatus,
      userToken,
      userType,
      userVip,
      usercode,
      vipDays,
      vipEndDate,
      vipStartDate,
      isgetgift,
      ischeckphones,
      // cf
      selected,
    } = params
    
    this.address             = address
    this.applyStatus         = applyStatus
    this.authStatus          = authStatus
    this.channelName         = channelName
    this.chatUserName        = chatUserName
    this.city                = city
    this.coder               = coder
    this.compCheckStatus     = compCheckStatus
    this.compLicensePath     = compLicensePath
    this.compLinkmanName     = compLinkmanName
    this.compLinkmanPhone    = compLinkmanPhone
    this.compRegNo           = compRegNo
    this.compRunExtent       = compRunExtent
    this.compScope           = compScope
    this.compSetUpDate       = compSetUpDate
    this.companyFullname     = companyFullname
    this.companyUserId       = companyUserId
    this.createTime          = createTime
    this.email               = email
    this.friendRemark        = friendRemark
    this.hxPwd               = hxPwd
    this.identity            = identity
    this.isfriend            = isfriend
    this.listSkill           = listSkill
    this.loginName           = loginName
    this.maxUserNums         = maxUserNums
    this.openId              = openId
    this.openproject         = openproject
    this.ownedEnterprises    = ownedEnterprises
    this.personalDescribe    = personalDescribe
    this.phone               = phone
    this.province            = province
    this.provinceCoder       = provinceCoder
    this.punishNum           = punishNum
    this.sign                = sign
    this.spaceBackgroundPath = spaceBackgroundPath
    this.threeOne            = threeOne
    this.thumbnail           = thumbnail
    this.updateTime          = updateTime
    this.updateremark        = updateremark
    this.userCardNo          = userCardNo
    this.userId              = userId
    this.userIntegral        = userIntegral
    this.userName            = userName
    this.userPassword        = userPassword
    this.userPosition        = userPosition
    this.userRealName        = userRealName
    this.userSex             = userSex
    this.userStatus          = userStatus
    this.userToken           = userToken
    this.userType            = userType
    this.userVip             = userVip
    this.usercode            = usercode
    this.vipDays             = vipDays
    this.vipEndDate          = vipEndDate
    this.vipStartDate        = vipStartDate
    this.isgetgift           = isgetgift
    this.ischeckphones       = ischeckphones

    // cf
    this.selected            = selected
  }

  /**
   * 优先显示名
   */
  priorableName(){
    const priorableName = vue.$api.user.priorableName(this)
    return priorableName
  }

  /**
   * 搜索名
   * @param {*} params 
   * @returns 
   */
  search(params = {}){
    const {keyword}                              = params
    const {userName, friendRemark, chatUserName} = this
    if(keyword){
      if(friendRemark && friendRemark.toLowerCase().indexOf(keyword.toLowerCase()) != -1){
        return vue.$tools.highlightKeywords(friendRemark, keyword)
      }
      if(chatUserName && chatUserName.toLowerCase().indexOf(keyword.toLowerCase()) != -1){
        return vue.$tools.highlightKeywords(chatUserName, keyword)
      }
      if(userName && userName.toLowerCase().indexOf(keyword.toLowerCase()) != -1){
        return vue.$tools.highlightKeywords(userName, keyword)
      }

      return false
    }
    return false
  }

  /**
   * '@'显示用名 为用户的chatUserName 或 userName 不能是用户的备注名
   */
  atName(){
    const {chatUserName, userName} = this
    console.log(this)
    if(chatUserName??'' !== ''){
      return chatUserName
    }
    return userName
  }
}


export default UserInfo