<template>
  <div
    v-if="visible"
  >
    <common-wrapper
      height="100%"
      width="100%"
      max_width="70%"
      max_height="80%"
    >
      <div
        class="c_plugin_teaching_package_edit d-flex flex-column"
      >
        <common-title
          class="flex-shrink-0"
          @handleClose ="handleClose"
          :title="title"
          :close="true"
          background_color="#fff"
        >
        </common-title>
        <plugin-component
          class="plugin_component"
          @handleClose="handleClose"
          @handleSure="handleSure"
          @handleReturn="handleReturn"
        ></plugin-component>
      </div>
    </common-wrapper>
  </div>
</template>

<script>
import CommonTitle from '@/components/PopUp/Common/Title'
import CommonWrapper from '@/components/PopUp/Common/Wrapper'
import PluginComponent from '@/components/PopUp/TeachingPackage/Edit/index'
export default {
  components: {
    CommonWrapper,
    CommonTitle,
    PluginComponent
  },
  data(){
    return {
      visible: false,
      // title: '',
      // onSure: undefined,
      onCancel: undefined,
      onReturn: undefined,
      loading: false,
      params: undefined
    }
  },
  computed: {
    title() {
      const { teachingPackage } = this
      if (!teachingPackage) {
        return '新建教学包'
      }
      if (teachingPackage && teachingPackage.pPackage && teachingPackage.pPackage.id) {
        return '编辑教学包'
      }
      return '新建教学包'
    },
    teachingPackage() {
      const { params } = this
      const { teachingPackage } = params
      return teachingPackage
    },
    onSure() {
      const { params } = this
      const { onSure } = params
      return onSure
    }
  },
  methods: {
    handleClose(){
      this.visible = false;
    },
    handleReturn(params){
      const {work} = params
      const {onReturn, list} = this
      if(onReturn){
        console.log('onReturn')
        onReturn({list, work})
      }
      this.handleClose()
    },
    async handleSure(){
      if(this.onSure){
        if(this.loading){
          return
        }
        this.loading = true
        await this.onSure()
        this.loading = false
      }
      this.handleClose()
    },
  }
}
</script>

<style lang="scss" scoped>
.c_plugin_teaching_package_edit{
  background-color: #fff;
  width: 100%;
  flex: 1;
  border-radius: 4px;
  overflow-y: auto;
  .main{
    flex: 1;
    width: 100%;
    overflow-y: auto;
  }
  .plugin_component{
    // border-top: 1px solid #f4f4f4;
  }
}
</style>
