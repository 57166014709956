var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("task", {
    staticClass: "mb-15",
    attrs: {
      p_task: _vm.p_task,
      menu: !_vm.preview && _vm.show_menu,
      current_folder: _vm.current_folder,
      switch_select: _vm.switch_select,
      show_from: false,
      disable_select: _vm.disable_select,
      "can-set-status": _vm.canSetStatus,
    },
    on: {
      handleClick: _vm.handleClick,
      handleCallTask: _vm.handleCallTask,
      handleShareTask: _vm.handleShareTask,
      handleRemoveCollect: _vm.handleRemoveCollect,
      handleTouchCollect: _vm.handleTouchCollect,
      handleStartSelect: _vm.handleStartSelect,
      handleSure: _vm.handleSure,
      handleRemove: _vm.handleRemove,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }