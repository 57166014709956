const app = {
  state: {
    loading: false,
    arPermission: true, // ar编辑权限
  },
  mutations: {
    setAppLoading (state, loading) {
      state.loading = loading
    },
    setAppArPermission (state, arPermission) {
      state.arPermission = arPermission
    }
  },
  actions: {
  }
}

export default app
