import axios from "axios";
import config from "./config/config"; // application/x-www-form-urlencoded
import collocate from "./config/collocate"; // application/json;charset=UTF-8
import Vue from "vue";
// import md5 from 'js-md5'
import router from "@/router";
import login from "./login";
import forget from "./forget";
import request from '@/api/request';
import common from '@/api/common';
import vue from '@/main';


export default {
  ...login,
  ...forget,
  ...request,
  ...common,
};

//http request 拦截器
axios.interceptors.request.use(
  config => {
    // // console.log(vue, vue.$userid,vue.$tools.getCookie('user').userId)
    // if(vue.$userid !== '' && vue.$userid != vue.$tools.getCookie('user').userId){
    //   config.stop && config.stop.cancel('取消请求');
    //   vue.$router.push({name:'login_sign'});
      
    // }
    if (localStorage.authorization || localStorage.client_info) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      //   let client_info = JSON.parse(localStorage.client_info);
      //   client_info.deviceToken = localStorage.authorization;
      //   config.headers.authorization =
      //     "authorization" + localStorage.authorization;
      //   config.headers.client_info = JSON.stringify(client_info);
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
//http response 拦截器
axios.interceptors.response.use(
  response => {
    if (response.data) {
      /*判断版本更新 */
      try {
        // 不检查是否更新的接口
        const unCheckUrl = [
          'project/sendAuthcode'
        ]
        const { request } = response
        const { responseURL } = request
        const isUnCheckUrl = unCheckUrl.find((unit) => {
          return responseURL.indexOf(unit) !== -1
        })
        if (!isUnCheckUrl) {
          vue.$utils_notify.systemUpdate()
        }
      } catch (e) {
        console.log(e)
      }
      switch (response.status) {
        //     case 91000:
        //     case 91001:
        //     case 91002:
        //       Vue.prototype.$message.error({
        //         message: response.data.message || response.data.msg,
        //         onClose() {
        //           localStorage.removeItem("authorization");
        //           sessionStorage.clear();
        //           router.push("/login");
        //         }
        //       });
        //       break;
        case 200:
          return response.data
        default:
          //   console.error(response.data.message || response.data.msg);
          return Promise.reject(response.data.message || response.data.msg)
      }
    }
  },
  error => {
    return Promise.reject(error);
  }
);
// export default API;
