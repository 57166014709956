<template>
    <div class="register">
        <div class="tab">
            <ul v-if="show"> 
                <li :class="step>=0?'pitchon':''">
                    <p>1</p>
                    <span @click="tabCLick(0)">验证手机号码</span>
                </li>
                <li :class="step>=1?'pitchon':''">
                    <div class="placeholder"></div>
                    <p>2</p>
                    <span @click="tabCLick(1)">密码设置</span>
                </li>
                <li :class="step>=2?'pitchon':''">
                    <div class="placeholder"></div>
                    <p>3</p>
                    <span @click="tabCLick(2)">实名认证及用户信息</span>
                </li>
            </ul>
            <div class="tabagreement" v-else>{{docName}}</div>
        </div>
        <div v-show="step==0" class="operate">
            <Telverification  @myevent="handleEvent" @user="usernameTel" />
        </div>
        <div v-show="step==1" class="operate">
            <Setpassword @myevent="handleEvent" @pass="passwordTel" />
        </div>
        <div v-show="step==2" class="operate">
            <Authentication @complete="complete" />
        </div>
    </div>
</template>
<script>
import Telverification from './register/Telverification';
import Setpassword from './register/Setpassword';
import Authentication from './register/Authentication';
import bus from '@/bus'
    export default {
        data() {
            return {
                step:0,
                show:true,
                mobile:{
                    // 测试号 19999999885
                    phone:"" ,
                    userPassword:"",
                    thumbnail:"https://img-icon.ourutec.com/",
                    userName:"",
                    userRealName:"",
                    userCardNo:""
                },
                docName:'',
            }
        },
        components: {
            Telverification,
            Setpassword,
            Authentication,
        },
        methods: {
            // 调试注册步骤
            tabCLick(num){
                // this.step = num
            },
            handleEvent(num, docName){
                if(typeof num == 'number'){
                    this.step = num
                }else{
                    this.show = num
                }
                if(docName){
                  this.docName = docName;
                }else{
                  this.docName = '用户注册及免责协议';
                }
                
            },
            usernameTel(user){
                this.mobile.phone = user
            },
            passwordTel(pass){
                this.mobile.userPassword = pass
            },
            async complete(name,idNumnber,nickname,imgUrl){
                this.mobile.userRealName = name
                this.mobile.userCardNo = idNumnber
                this.mobile.userName = nickname
                this.mobile.thumbnail = imgUrl
                let params = this.mobile
                this.$http.mobileRegisterGeren(params).then(  async res => {
                    // console.log(res)
                    if(res.message === "success"){
                      // document.cookie = `miaohangUsername=${this.username}`;
                      let loginRes = await this.$api.doLogin(this.mobile.phone, this.$md5(this.mobile.userPassword));
                      if(loginRes){
                        // 注册成功
                        this.$router.push('/login/success')
                      }
                      // this.$router.push('/')
                    }else{
                      this.$message(res.describe);
                    }
                })
            }
        },
    }
</script>
<style lang="less" scoped>
li{
  list-style: none;
}
    .register {
        width: 1200px;
        height: 650px;
        margin: auto;
        background: #fff;

        .tab{
            float: left;
            width:  1200px;
            height: 90px;
            border-bottom:2px solid #f0f0f0;
            ul{
                width: 730px;
                flex-wrap: wrap;
                margin: 0 auto;
                li{
                    float: left;
                    height: 88px;
                    padding: 0 30px;
                    p{
                        float: left;
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        background: #e5eef6;
                        margin-top: 32px;
                        font-size: 18px;
                        line-height: 26px;
                        text-align: center;
                        color:#7e9eba;
                    }
                    span{
                        float: left;
                        padding-left: 10px;
                        font-size: 18px;
                        line-height: 90px;
                        color:#7e9eba;
                    }
                }
                .placeholder{
                    float: left;
                    width: 50px;
                    height: 90px;
                }
                .pitchon{
                    border-bottom: 2px solid #0054a6;
                    p{
                        background: #0054a6;
                        color: #fff;
                    }
                    span{
                        color:#333333
                    }
                }
            }
            .tabagreement{
                width: 1200px;
                height: 90px;
                font-size: 26px;
                line-height: 90px;
                text-align: center;
            }
        }
        .operate{
            float: left;
            width:  1200px;
            height: 550px;
        }
    }
</style>