<template>
    <div class="template">
        <div class="tel">
            <ul>
                <li>
                    <p>真 实 姓 名:</p>
                    <div class="ipt">
                        <input type="text" placeholder="请输入真实姓名" v-model="username" />
                        <div @click="usernameEmpty" v-show="username">
                            <img src="../../../assets/icon-shut.png">
                        </div>
                    </div>
                </li>
                <li>
                    <p>身份证号码:</p>
                    <div class="ipt">
                        <input type="text" placeholder="请输入身份证号码" v-model="idNumber" maxlength="22"/>
                        <div @click="idNumberEmpty" v-show="idNumber">
                            <img src="../../../assets/icon-shut.png" />
                        </div>
                    </div>
                </li>
                <span v-html="usernameWarn"></span>
                <div class="headPortrait">
                    <img :src="imgUrl2 ? `${$avatar_url}${imgUrl2}` : require('@/assets/images/common/avatar_default.png')" alt="">
                    <div>
                        <el-upload
                          ref="uploadAvatar"
                          style="height:auto" :http-request="UploadImage" action="/" :limit="1" :show-file-list="false">
                            <h1>{{imgUrl2? '修改头像' : '上传头像'}}</h1> <!-- h1,h2,h3,h4{font-weight:normal;font-size:12px} -->
                        </el-upload>
                        <h2>*建议尺寸120*120px</h2>
                    </div>
                </div>
                <li>
                    <p>用 户 昵 称:</p>
                    <div class="ipt">
                        <input type="text" placeholder="请输入昵称" v-model="nickname" />
                        <div @click="nicknameEmpty" v-show="nickname">
                            <img src="../../../assets/icon-shut.png" />
                        </div>
                    </div>
                </li>
            </ul>
            <span class="nicknamewarn" v-html="Warn"></span>
            <div class="btn">
                <button @click="accomplish" class="low">完成注册</button>
                <button @click="cancelRegister" class="cancel">取消注册</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import bus from '@/bus'
export default {
    data() {
        return {
            BASEURL: process.env.VUE_APP_BASEURL,
            username: "",
            idNumber: "",
            nickname: "",
            usernameWarn: "",
            Warn: "",
            // thumbnail: '', // 提交后台的url头像
            imgUrl: 'https://lanhu.oss-cn-beijing.aliyuncs.com/ps50498722654751b0-531f-4d23-ab73-c197881cf02e',
            imgUrl2: ``
        }
    },
    methods: {
        cancelRegister() {
            this.$router.back()
        },
        accomplish() {
            if (!this.nickname.length) {
                this.Warn = "请输入昵称"
            } else if(!this.idNumber || !this.username.length){
                this.usernameWarn = "请输入正确的实名信息";
            } else {
                this.Warn = ""
                this.$emit("complete",this.username,this.idNumber,this.nickname,this.imgUrl2)
                // this.$router.push("/login/success")
                
            }
            /* if(判断用户姓名、身份证号码是否正确 && this.nickname){
                完成注册提交数据
            }else if(！判断用户姓名、身份证号码是否正确){
                this.usernameWarn = "*请输入正确的真实姓名"
            }else{
                this.Warn = "*请输入昵称"
            } */

        },
        UploadImage(param) { // 文件提交
            const formData = new FormData()
            formData.append('Filedata', param.file) // 要提交给后台的文件
            // console.log(formData.get('Filedata')); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
            let config = { // 添加请求头
                headers: { 'Content-Type': 'multipart/form-data' }
            };
            axios.post(this.BASEURL + '/project/upLoadPic', formData, config)
                .then(res => {
                    // console.log(res, 'sssss');
                    this.imgUrl = res.contents.picurl.replace(/\|\|/, '')
                    // console.log(this.imgUrl);
                    let reg = /(\|\|)(\S*)/
                    this.imgUrl2 = reg.exec(res.contents.picurl)[2]
                    // console.log('imgUrl2', this.imgUrl2)
                    this.$refs.uploadAvatar.clearFiles();
                })
        },
        usernameEmpty() {
            this.username = ""
        },
        idNumberEmpty() {
            this.idNumber = ""
        },
        nicknameEmpty() {
            this.nickname = ""
        },
    },
}
</script>
<style lang="less" scoped>
li{
  list-style: none;
}
.template {
    width: 1200px;
    height: 550px;
    .tel {
        width: 415px;
        height: 500px;
        margin: 0 auto;
        padding-top: 50px;

        ul {
            float: left;
            display: block;
            width: 100%;
            // background: #000;
            flex-wrap: wrap;
            li {
                width: 100%;
                height: 68px;
                p {
                    float: left;
                    height: 68px;
                    font-size: 18px;
                    line-height: 68px;
                    color: #0054a6;
                    margin-right: 25px;
                }
                .ipt {
                    float: right;
                    margin: 10px 0;
                    width: 285px;
                    height: 47px;
                    border-bottom: 1px solid #d5d9db;
                    input {
                        float: left;
                        border: 0;
                        outline: none;
                        height: 19px;
                        margin-top: 16px;
                        font-size: 18px;
                        color: #83929c;
                    }
                    img {
                        float: right;
                        width: 18px;
                        height: 12px;
                        margin: 17px 0;
                    }
                }
            }
            span {
                width: 100%;
                height: 15px;
                font-size: 16px;
                color: #d82e0d;
            }
            .headPortrait {
                width: 100%;
                height: 92px;
                margin: 30px 0 5px;
                img {
                    float: left;
                    width: 92px;
                    height: 92px;
                    // border-radius: 50%;
                    margin-right: 32px;
                    border-radius: 4px;
                }
                div {
                    float: left;
                    width: 290px;
                    height: 70px;
                    margin: 11px 0;
                    h1 {
                        width: 100%;
                        height: 35px;
                        font-size: 18px;
                        line-height: 35px;
                        color: #010101;
                    }
                    h2 {
                        width: 100%;
                        height: 35px;
                        font-size: 14px;
                        line-height: 35px;
                        color: #666;
                    }
                }
            }
        }
        .nicknamewarn {
            width: 100%;
            height: 16px;
            font-size: 16px;
            line-height: 16px;
            color: #d82e0d;
        }
        .btn {
            float: left;
            width: 100%;
            height: 50px;
            margin-top: 30px;
            button:nth-of-type(1) {
                float: left;
                width: 200px;
                height: 50px;
                background: rgba(0, 84, 166, 1);
                border: 0;
                color: #ffffff;
                font-size: 18px;
                border-radius: 5px;
                margin-right: 15px;
            }
            .low:hover {
                background: #004080;
            }
            button:nth-of-type(2) {
                float: left;
                width: 200px;
                height: 50px;
                background: #e5eef6;
                color: rgba(0, 84, 166, 1);
                font-size: 18px;
                border: 0;
                border-radius: 5px;
            }
            .cancel:hover {
                background: #ccdded;
            }
        }
    }
}
</style>