<!--
 * @Author       : Hugo
 * @Date         : 2020-06-15 08:41:09
 * @LastEditTime : 2024-02-01 20:58:59
 * @LastEditors  : hugo
 * @Description  :
 * @FilePath     : /pmcs_web/src/App.vue
 * @^_^
-->
<template>
  <div
    id="app"
    v-loading="appLoading"
  >
    <router-view
      v-if="isRouterAlive"
    ></router-view>
     <!-- 页面切换 -->
     <!-- <Table></Table> -->
    <div class="test_print_group" v-if="0">
      <div class="print_group">
        <div class="title">测试打印标题</div>
        <div class="list">
          <div class="unit" v-for="item in 60" :key="item">测试打印行</div>
        </div>
      </div>
    </div>
    <!-- <become-member></become-member> -->
    <!-- <payment-status
      title="恭喜您成为我们的VIP用户"
      jump_hint="会员与消费"
      :jump="true"
    ></payment-status> -->
    <!-- <member-rights></member-rights> -->
    <!--<identification-code-->
    <!--&gt;</identification-code>-->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'

export default {
  components: {
  },
  data(){
    return {
      isRouterAlive: true,
    }
  },
  computed: {
    /**禁止浏览器返回 */
    allow_back(){
      return this.$store.state.allow_back
    },
    ...mapState({
      appLoading: state => state.app.loading,
    }),
  },
  watch: {
    /**禁止浏览器返回 */
    allow_back: function(){
      this.allowBack();
    }
  },

  provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
        reload: this.reload
    }
  },
  methods:{
    onLoad(){
      this.$store.dispatch('imLogin');
    },
    /**
     * @description: 刷新token
     * @param {*}
     * @return {*}
     */
    async refreshToken() {
      let password = this.$tools.getCookie('password');
      let user = this.$tools.getCookie('user');
      let username = user.loginName;
      let data = {
        username,
        password,
        logintype: 4,
      };
        let res = await this.$http.request('/project/loginAuth', data)
        const { message } = res
        if (message === 'success') {
          for(let i in res.contents){
            this.$tools.setCookie(i, res.contents[i]);
          }
          if (res.contents.user) {
            store.commit('setAppArPermission', Boolean(res.contents.user.areditflag))
          }
        } else {
          console.log('登陆错误')
          this.$api.common_logout()
        }
    },
    /**
     * @description: 刷新用户信息
     * @param {*}
     * @return {*}
     */
    async getUser(){
      let userId = this.$tools.getUserId();
      this.$store.commit('set_login_user_id', userId);
      let res;
      if(userId){
        try{
          res = await this.$http.request('/project/getUserById', {userId});
          if(res.message == 'success'){
            if(res.contents && res.contents.user){
              const userinfo = res.contents.user;
              const origin_user = this.$tools.getCookie('user');
              const new_user = Object.assign(
                {},
                origin_user,
                userinfo,
                {
                  vipEndDate: origin_user?.vipEndDate || null,
                  vipStartDate: origin_user?.vipEndDate || null,
                  usercode: origin_user?.usercode || null
                }
              )
              this.$tools.setCookie('user', new_user);
            }
          }
        }catch(err){
          console.log(err);
        }
      }
    },
    /**
     * @description: 判断是否需要请求数据
     * @param {*}
     * @return {*} t: 需要 f: 不需要
     */
    needGetData(){
      let status = true;
      const {name, path} = this.$route;
      const prohibitivted_array = [
        '/download/index',
        '/login',
        '/active_page',
        '/card_page',
        '/static',
        '/order/getBatchCodeExcel',
        '/PrintPage'
      ]; // 禁止的name数组
      let is_in = prohibitivted_array.findIndex((item) => {
        const reg = new RegExp(item, 'ig')
        const result = reg.test(window.location.href)
        return result
      })
      if (is_in != -1) {
        status = false
      }
      return status
    },
    /**禁止浏览器返回 */
    allowBack(){
      const {allow_back} = this.$store.state;
      window.onpopstate = () => { if (!allow_back) {
        // 这个allowBack 是存在vuex里面的变量
        history.go(1)
      } }
    },
    reload () {
      this.isRouterAlive = false            //先关闭，
      this.$nextTick(function () {
        this.isRouterAlive = true         //再打开
      })
    }
  },
  async created(){
    this.$nextTick(async function(){
    })
  },
  async mounted(){
    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL);
    });
    this.$nextTick(async function () {
      this.allowBack()
      // 检测域名变化
      const is_ourutec = this.$api.app.domainChange();
      if (is_ourutec) {
        return
      }
      const is_utec = this.$api.app.is_ourutec()
      if (is_utec) {
        return
      }

      const needGetData = this.needGetData()
      // 不重新登录
      const nologin = this.$tools.getCookie('nologin')
      if (nologin && needGetData) {
        console.log(nologin && !needGetData)
        this.$closeConfirm()
        this.$router.push({ path: "/login" })
        return
      }
      if (needGetData) {
        await this.refreshToken()
        this.onLoad()
      }
      this.$api.app.labelPageActivationEventListener()
    })

    // 提醒通知测试
    // this.$reminder_notice({desc: 111});

    // 标签页被激活 处理兼容
    var bowhidden="hidden" in document?"hidden": "webkithidden" in document?"webkithidden": "mozhidden" in document ?"mozhidden": null;
    var vibchage="visibilitychange" || "webkitvisibilitychange" || "mozvisibilitychange";
    document.addEventListener(vibchage, ()=>{
      /*ie10+  moz  webkit  默认*/
      if(!document[bowhidden]) /*false*/
      {
        // // console.log("激活");
        this.$api.version.check();
      }
      else{
        /*true*/
        // // console.log("隐藏");
      }
    });
  }

}

</script>
<style lang='less' scoped>
@import'./style/index.less';
#app{
  width: 100%;
  height: 100%;
}

</style>
<style>
body{
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
body::-webkit-scrollbar {
  width: 0 !important
}
</style>
