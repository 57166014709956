var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "template" }, [
    _c("div", { staticClass: "tel" }, [
      _c("ul", [
        _c("li", [
          _c("p", [_vm._v("真 实 姓 名:")]),
          _c("div", { staticClass: "ipt" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username",
                },
              ],
              attrs: { type: "text", placeholder: "请输入真实姓名" },
              domProps: { value: _vm.username },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.username = $event.target.value
                },
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.username,
                    expression: "username",
                  },
                ],
                on: { click: _vm.usernameEmpty },
              },
              [
                _c("img", {
                  attrs: { src: require("../../../assets/icon-shut.png") },
                }),
              ]
            ),
          ]),
        ]),
        _c("li", [
          _c("p", [_vm._v("身份证号码:")]),
          _c("div", { staticClass: "ipt" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.idNumber,
                  expression: "idNumber",
                },
              ],
              attrs: {
                type: "text",
                placeholder: "请输入身份证号码",
                maxlength: "22",
              },
              domProps: { value: _vm.idNumber },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.idNumber = $event.target.value
                },
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.idNumber,
                    expression: "idNumber",
                  },
                ],
                on: { click: _vm.idNumberEmpty },
              },
              [
                _c("img", {
                  attrs: { src: require("../../../assets/icon-shut.png") },
                }),
              ]
            ),
          ]),
        ]),
        _c("span", { domProps: { innerHTML: _vm._s(_vm.usernameWarn) } }),
        _c("div", { staticClass: "headPortrait" }, [
          _c("img", {
            attrs: {
              src: _vm.imgUrl2
                ? `${_vm.$avatar_url}${_vm.imgUrl2}`
                : require("@/assets/images/common/avatar_default.png"),
              alt: "",
            },
          }),
          _c(
            "div",
            [
              _c(
                "el-upload",
                {
                  ref: "uploadAvatar",
                  staticStyle: { height: "auto" },
                  attrs: {
                    "http-request": _vm.UploadImage,
                    action: "/",
                    limit: 1,
                    "show-file-list": false,
                  },
                },
                [
                  _c("h1", [
                    _vm._v(_vm._s(_vm.imgUrl2 ? "修改头像" : "上传头像")),
                  ]),
                ]
              ),
              _c("h2", [_vm._v("*建议尺寸120*120px")]),
            ],
            1
          ),
        ]),
        _c("li", [
          _c("p", [_vm._v("用 户 昵 称:")]),
          _c("div", { staticClass: "ipt" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.nickname,
                  expression: "nickname",
                },
              ],
              attrs: { type: "text", placeholder: "请输入昵称" },
              domProps: { value: _vm.nickname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.nickname = $event.target.value
                },
              },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.nickname,
                    expression: "nickname",
                  },
                ],
                on: { click: _vm.nicknameEmpty },
              },
              [
                _c("img", {
                  attrs: { src: require("../../../assets/icon-shut.png") },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _c("span", {
        staticClass: "nicknamewarn",
        domProps: { innerHTML: _vm._s(_vm.Warn) },
      }),
      _c("div", { staticClass: "btn" }, [
        _c("button", { staticClass: "low", on: { click: _vm.accomplish } }, [
          _vm._v("完成注册"),
        ]),
        _c(
          "button",
          { staticClass: "cancel", on: { click: _vm.cancelRegister } },
          [_vm._v("取消注册")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }