var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_teaching_package_select_task_router_bar d-flex" },
    [
      _c(
        "div",
        {
          staticClass: "router cp",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.goToAllPackage.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    全部教学包\n  ")]
      ),
      _c("div", { staticClass: "symbol" }, [_vm._v("\n    >\n  ")]),
      _c("div", { staticClass: "router active" }, [
        _vm._v("\n    " + _vm._s(_vm.packageName) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }