var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "c_collect_main bbox d-flex flex-column" },
    [
      _c("navigation-bar", {
        staticClass: "flex-shrink-0",
        attrs: { preview: _vm.preview, top_jump: _vm.top_jump },
      }),
      _c(
        "div",
        { staticClass: "main_wrapper d-flex flex-column" },
        [
          (_vm.folder_list && _vm.folder_list.length) ||
          (_vm.file_list && _vm.file_list.length)
            ? [
                _vm.folder_list && _vm.folder_list.length
                  ? _c("folder-component", {
                      ref: "folder_list",
                      attrs: {
                        list: _vm.folder_list,
                        preview: _vm.preview,
                        current_folder: _vm.current_folder,
                        switch_select: _vm.switch_select_folder,
                        disable_select: _vm.switch_select_file,
                        show_menu: _vm.show_menu,
                        call: _vm.call,
                      },
                      on: { handleStartSelect: _vm.handleStartSelectFolder },
                    })
                  : _vm._e(),
                _vm.file_list && _vm.file_list.length
                  ? _c("file-component", {
                      attrs: {
                        list: _vm.file_list,
                        preview: _vm.preview,
                        current_folder: _vm.current_folder,
                        nomore: _vm.nomore_file,
                        show_menu: _vm.show_menu,
                        call: _vm.call,
                        switch_select: _vm.switch_select_file,
                        disable_select: _vm.switch_select_folder,
                      },
                      on: {
                        handleStartSelect: _vm.handleStartSelectFile,
                        handleLoadMore: _vm.handleLoadMoreFile,
                      },
                    })
                  : _vm._e(),
              ]
            : _c(
                "div",
                {
                  staticClass:
                    "none_group bbox d-flex alig-center justify-center",
                },
                [_vm._m(0)]
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "none" }, [
      _c("img", {
        staticClass: "img mb-20",
        attrs: { src: require("@/assets/images/common/none.png"), alt: "" },
      }),
      _c("div", { staticClass: "text" }, [_vm._v("暂无任务")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }