import vue from '@/main.js';
import fun from './index';
import teachFun from './teachFun';
class EditTeach{
  main = async () => {
    // 创建task 拿到taskId
    // 附带价格信息 * 100

    const task_res = await fun.editTask();
    if(task_res && task_res.contents){

      const {taskLog} = task_res.contents;
      let taskId;
      if(taskLog === null){
        taskId = vue.$store.state.task.task.id;
      }else{
        taskId = taskLog.taskId;
      }
      // const {teach_files} = vue.$store.state;
      // const {fileList,linkList,videoList} = teach_files;
  
  
      // 拿到教学附件
      // projectTask/addFile 控件绑定附件 teach: 1 taskId chatId
      // projectTeach/addTeachFile 绑定 附件id type: 3 content: ''
      const add_res = await teachFun.uploadTeachFile({taskId});
      await teachFun.deleteTeachFile({taskId});
    }
    return task_res;
  }
}

const editTeach = new EditTeach();

export default editTeach.main;