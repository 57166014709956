<template>
  <div class="c_thumbnail_task ml-5 mr-10 mb-10">
    <div
      class="new_content_group"
      @click.stop="openTask"
    >
      <div
        v-show="canDelete"
        class="delete cp"
        @click.stop="handleDelete"
      ></div>
      <div
        v-show="canSelect"
        class="select_group cp"
        :class="{active: selected}"
        @click.stop="handleSelected"
      ></div>
      <div
        v-show="nonselectable"
        class="nonselectable"
      ></div>
      <div
        v-if="pTask.colour"
        class="color_group"
      >
        <template
          v-for="(item,index) in getColorList(pTask)"
        >
          <div
            :key="index"
            class="color"
            :style="{ 'border-left-color': item }"
          ></div>
        </template>
      </div>
      <div
        class="dot"
        v-show="pTask.dot"
      ></div>
      <div class="info_group">
        <div class="info_box d-flex justify-space-between">
          <template>
            <div class="info cp un_sel">
              <title-status-tag
                class="status_tag_group"
                :level-status="String(pTask.levelStatus)"
                :process-status="String(pTask.processStatus)"
                size="14px"
              ></title-status-tag>
              <span class="task_name">
                {{ pTask.taskDetail || pTask.name || pTask.taskName }}
              </span>
            </div>
          </template>
          <el-popover
            placement="right-start"
            width="400"
            trigger="click"
            :visible-arrow="false"
            popper-class="c_collect_folder_unit_popover"
            v-model="switchMenu"
            @hide="hidePopoverHandler()"
            @show="popoverShowHandler()"
          >
            <!-- 自定义文件夹菜单 -->
            <div
              class="menu_group"
              ref="menu_group"
            >
              <div
                class="menu_list_group"
              >
                <div
                  v-if="canSetStatus"
                  class="item un_sel cp"
                  @click.stop="touchSetStatusTag();"
                >
                  <div
                    class="text"
                  >
                    设置状态
                  </div>
                </div>
                <div
                  v-if="canSetGeneralTag"
                  class="item un_sel cp"
                  @click.stop="stouchSetGeneralTag()"
                >
                  <div
                    class="text"
                  >
                    设置标签
                  </div>
                </div>
                <div
                  v-if="canCall"
                  class="item un_sel cp"
                  @click.stop="callTask()"
                >
                  <div
                    class="text"
                  >
                    调用任务
                  </div>
                </div>
                <div
                  v-if="canShare"
                  class="item un_sel cp"
                  @click.stop="shareTask()"
                >
                  <div
                    class="text"
                  >
                    分享任务
                  </div>
                </div>
                <div
                  v-if="canCollect"
                  class="item un_sel cp"
                  @click.stop="touchCollect()"
                >
                  <div
                    class="text"
                  >
                    {{ `${ pTask.collectionflag ? '取消' : '' }收藏` }}
                  </div>
                </div>
                <div
                  v-if="canDeleteTask"
                  class="item un_sel cp"
                  @click.stop="deleteTask()"
                >
                  <div
                    class="text"
                  >
                    删除任务
                  </div>
                </div>
              </div>
            </div>
            <button
              v-show="showMenu"
              class="more_btn button un_sel"
              slot="reference"
              @click.stop="$api.stopPropagation($event)"
            ></button>
          </el-popover>

        </div>

        <div
          class="time_box"
        >
          {{ pTask.updateTime | universalDate }}
        </div>
        <!-- 剩余天数 -->
        <!--<div-->
        <!--  v-if="recycle"-->
        <!--  class="time_box"-->
        <!--&gt;-->
        <!--  {{ pTask.deleteDays !== null ? `剩余${pTask.deleteDays}天` : timeRemaining(pTask.updateTime) }}-->
        <!--</div>-->
      </div>
      <div
        class="template_info_group"
        :class="{ separate : 1 }"
      >
        <!--<div-->
        <!--  v-if="Number(pTask.taskType) === 2 && pTask.draft !== 1"-->
        <!--&gt;</div>-->
        <div
          v-if="pTask.createuserthumbnail"
          class="avatar_group"
        >
          <el-tooltip
            class="item"
            effect="dark"
            :content="pTask.createusername"
            placement="bottom"
            :open-delay="500"
          >
            <img
              :src="`${$avatar_url}${pTask.createuserthumbnail}@!small200`"
              alt=""
              class="avatar"
            >
          </el-tooltip>
        </div>
        <div
          v-else
        ></div>
        <div
          class="tag_box"
        >
          <div
            v-show="pTask.isremind"
            class="tag reminder"
          ></div>
          <!--<div-->
          <!--  v-show="draft"-->
          <!--  class="tag draft"-->
          <!--&gt;草</div>-->
          <div
            v-show="Number(pTask.taskType) === 2 || Number(pTask.taskType) === 3"
            class="tag teach"
          >教</div>
          <div
            v-if="pTask.hide"
            class="tag privacy"
          >私</div>
        </div>
        <!-- <div
          v-if="sell"
          class="check_status"
          :class="[transformCheckStaus(item.checkstatus).class_name]"
        >
          {{transformCheckStaus(item.checkstatus).text}}
        </div> -->
      </div>
    </div>
    <div
      v-if="showFrom"
      class="from_group d-flex bbox"
    >
      <div
        class="text"
      >
        来源:
      </div>
      <el-tooltip
        class="item"
        effect="dark"
        :content="pTask.from"
        placement="bottom-start"
        :open-delay="500"
      >
        <div
          class="name"
        >
          {{ pTask.from }}
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import TitleStatusTag from '@/components/Task/Main/LineBar/TaskTitle/TitleStatusTag'
import global from '@/api/global'
import moduleTask from '@/api/modules/task'
// import ThumbTask from '@/components/Common/ThumbTask'
export default {
  name: 'ThumbnailTask',
  components: {
    TitleStatusTag,
    // ThumbTask
  },
  props: {
    pTask: {
      type: Object,
      default: undefined
    },
    canSelect: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    // 不可选择的状态（已经被选中且不可取消）
    nonselectable: {
      type: Boolean,
      default: false
    },
    // 来源
    showFrom: {
      type: Boolean,
      default: false
    },
    // 菜单
    showMenu: {
      type: Boolean,
      default: false
    },
    canSetStatus: {
      type: Boolean,
      default: false
    },
    canSetGeneralTag: {
      type: Boolean,
      default: false
    },
    canShare: {
      type: Boolean,
      default: false
    },
    canCollect: {
      type: Boolean,
      default: false
    },
    canCall: {
      type: Boolean,
      default: false
    },
    canDeleteTask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      switchMenu: false
    }
  },
  methods: {
    deleteTask() {
      this.closeMenu()
    },
    callTask() {
      this.closeMenu()
      moduleTask.callTask({
        taskId: this.pTask.id,
        config: {
          select_target: true,
          jump: true,
          use: true,
          open_by_call: false, // 通过调用打开, 如果关闭时modified为false 则返回新建页
          open_with_call_page: false,
        }
      })
    },
    openTask() {
      this.closeMenu()
      moduleTask.openTask({
        taskId: this.pTask.id,
        config: {
          edit: true
        }
      })
    },
    async touchCollect() {
      this.closeMenu()
      // TODO 选择文件夹
      const { pTask } = this
      if (pTask.collectionflag) {
        if (!this.loading) {
          this.loading = true
          const res = await global.doPost({
            url: '/projectCustom/deleteFolderTask',
            data: {
              deleteIds: pTask.id,
            }
          })
          this.loading = false
          if(res.message === 'success'){
            pTask.collectionflag = 0
            this.$notice({
              desc:"移除成功",
              type: 'success'
            })
          }
        }
      } else {
        this.$collectSelectRoute({
          onSure: async (params) => {
            const { p_folder } = params
            const res = await global.doPost({
              url: '/projectCustom/addFolderTask',
              data: {
                folderId: p_folder.id,
                addIds: pTask.id
              }
            })
            if (res.message === 'success') {
              pTask.collectionflag = 1
              this.$notice({
                desc:"收藏成功",
                type: 'success'
              })
              // this.handleClose()
            }
          }
        })
      }
    },
    shareTask() {
      this.closeMenu()
      this.$api.share.doStartShare({
        data: Object.assign(
          {},
          this.pTask,
        ),
        type: 1
      })
    },
    hidePopoverHandler() {
      // this.start_moving = false
    },
    popoverShowHandler() {
      this.setPopoverZ()
    },
    setPopoverZ() {
      this.$nextTick(() => {
        document.querySelectorAll('.c_collect_folder_unit_popover').forEach((item) => {
          item.style.zIndex = this.$utils.getZIndex()
        })
      })
    },
    touchSetStatusTag() {
      this.closeMenu()
      this.$openSetStatusTag({
        title: '任务状态',
        pTask: this.pTask
      })
    },
    stouchSetGeneralTag () {
      this.closeMenu()
      this.$openSetGeneralTag({
        title: '任务状态',
        pTask: this.pTask
      })
    },
    closeMenu() {
      this.switchMenu = false
    },
    handleDelete(){
      this.$emit('handleDelete', { pTask: this.pTask })
    },
    handleSelected() {
      this.$emit('handleSelected', { pTask: this.pTask })
    },
    /**
     * @description: 获取颜色列表
     * @param {*} item
     * @return {*}
     */
    getColorList(item) {
      const { colour } = item
      if (!colour) {
        return ''
      }
      const color_list = colour.split(',')
      return color_list
    }
  }
}
</script>

<style scoped lang="scss">
.c_thumbnail_task{
  border-radius:10px;
  width:160px;
  // overflow: hidden;
  .new_content_group{
    @include bbox;
    width: 160px;
    height: 140px;
    background: rgba(244, 244, 244, 1);
    border: 1px solid#E8EEFF;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    // margin-left: 10px;
    // margin-bottom: 20px;
    position: relative;
    z-index: 2;
    .select_group{
      @include transition;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 3;
      top: -6px;
      left: -6px;
      background-image: url(~@/assets/images/task_thumb/unselected.png);
      &.active{
        background-image: url(~@/assets/images/task_thumb/selected.png);
      }
    }
    .nonselectable{
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 3;
      top: -6px;
      left: -6px;
      background-image: url(~@/assets/images/task_thumb/nonselectable.png);
    }
    .delete{
      @include transition;
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 3;
      top: -6px;
      left: -6px;
      background-image: url(~@/assets/images/delete/delete20.png);
    }
    .color_group{
      @include flex;
      flex-direction: column;
      position: absolute;
      height: 100%;
      width: 0;
      top: 0;
      left: 0px;
      .color{
        flex:1;
        width:5px;
        border: none;
        border-left: 4px solid #FFB038;
        &:first-child{
          border-top-left-radius: 20px;
        }
        &:last-child{
          border-bottom-left-radius: 20px;
        }
        &.placeholder{
          border-left-color: transparent;
        }
      }
    }
    .dot {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 12px;
      height: 12px;
      background: rgba(255, 59, 48, 1);
      border-radius: 50%;
    }
    .tag_group {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      background: {
        image: url(~@/assets/images/dialog/receive_tag.png);
        size: 100%;
      }
      width: 36px;
      height: 36px;
      .tag {
        font-size: 14px;

        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        position: absolute;
        z-index: 5;
        top: 2px;
        right: 3px;
      }
    }
    .unit_fun_btn{
      @include background(3px 15px);
      width: 20px;
      height: 20px;
      position: absolute;
      z-index: 9;
      background-image: url(~@/assets/images/control/fun_more.png);
      top:10px;
      right:2px;
    }
    .info_group {
      @include flexsb;
      @include bbox;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      height: 96px;
      padding: 19px 16px;
      background-color: #fff;
      border-radius: 10px 10px 0 0;
      .info_box{
        @include flexsb;
        @include bbox;
        align-items: flex-start;
        width: 100%;
        flex:1;
        height: 100%;
        padding-right: 13px;
        position: relative;
        .info{
          @include multi_line_intercept(2);
          width: 100%;
          font-size: 12px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 20px;
          text-align: justify;
          text-justify: inter-ideograph;
          text-align-last: left;
          word-break: break-all;
          .status_tag_group{
            margin-bottom: 2px;
            .tag{
              margin-bottom: 2px;
            }
          }
        }
        .more_btn{
          @include background;
          position: absolute;
          width: 16px;
          height: 16px;
          background: {
            image: url(~@/assets/images/control/fun_more.png);
            size: contain;
          }
          right: -8px;
          top: 0;
          z-index: 3;
        }
      }
      .time_box{
        @include bbox;
        padding-left: 7px;
        width: 100%;
        font-size: 12px;
        color:#666;
        border-left: 4px solid #e5e5e5;
        height: 12px;
        line-height: 12px;
      }

    }
    .template_info_group{
      @include flexsb;
      @include bbox;
      height: 40px;
      padding: 0 16px;
      border-radius: 0 0 10px 10px;
      background-color:#fff;
      &.separate{
        border-top: 2px solid #f8f8f8;
      }
      .avatar_group{
        @include flex;
        .avatar{
          display: block;
          width:26px;
          height: 26px;
          border-radius: 4px;
          overflow: hidden;
        }
        .new_chat_icon{
          margin-left: 8px;
          height: 19px;
          width: 20px;
        }
      }
      .tag_box{
        @include flex;
        justify-content: flex-end;
        .tag{
          font-size: 12px;
          line-height: 19px;
          padding: 0 7px;
          margin-left:4px;
          border-radius: 2px;
          &.privacy{
            color: #8C73F1;
            background-color: rgba(83, 47, 230, 0.08);
          }
          &.teach{
            color: #FF7200;
            background-color: rgba(255, 114, 0, 0.08);
          }
          &.draft{
            color: #5EC799;
            background-color: rgba(31, 175, 112, 0.08);
          }
          &.reminder{
            width: 20px;
            height: 20px;
            padding: 0;
            background-image: url(~@/assets/images/task/task_thumbnail_reminder.png);
          }
        }
      }
      .check_status{
        font-size:14px;
        color:#05AD00;
      }
      .teaching_group{
        flex: 1;
        .putaway{
          width: 54px;
          height: 22px;
          display: block;
        }
        .audit{
          font-size: 14px;
        }
      }
    }

    &.loadmore{
      @include flex;
      justify-content: center;
      background-color: transparent;
      box-shadow: none;
      border:none;
    }
  }
  .from_group{
    @include bbox;
    padding: 0 4px;
    overflow: hidden;
    width: 160px;
    font-size: 12px;
    padding-top: 10px;
    .text{
      flex-shrink: 0;
      color:#666;
      padding-right: 5px;
    }
    .name{
      @include single_line_intercept;
      flex: 1;
      width: 100%;
      color: $main_blue;
    }
  }
  .content_group{
    @include bbox;
    padding: 19px 16px;
    background-color: #fff;
    height: 105px;
    position: relative;
    .tag_group {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      background: {
        image: url(~@/assets/images/dialog/receive_tag.png);
        size: 100%;
      }
      width: 36px;
      height: 36px;
      .tag {
        font-size: 14px;

        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        position: absolute;
        z-index: 5;
        top: 2px;
        right: 3px;
      }
    }
    .content{
      @include multi_line_intercept(4);
      font-size:12px;

      font-weight:400;
      color:rgba(51,51,51,1);
      line-height:18px;
      text-align: justify;
      text-justify: inter-ideograph;
      text-align-last: left;
      word-break: break-all;
    }
  }
  .info_group{
    @include flexsb;
    @include bbox;
    padding: 0 16px;
    width:100%;
    height: 35px;
    .time{
      font-size:12px;

      font-weight:400;
      color:rgba(153,153,153,1);
    }
    .tag{
      width: 14px;
      height: 18px;
      background: {
        image: url(~@/assets/images/fun_template/tag.png);
      }
    }
    .check_status{
      font-size:14px;
      color:#05AD00;
    }
  }
}
</style>