<!--
 * @Author       : Hugo
 * @Date         : 2022-04-08 15:49:24
 * @LastEditTime : 2022-04-25 15:56:45
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/components/Collect/Folder/FolderUnit.vue
 * @Description  : 
 * @^_^          : 
-->
<template>
  <div
    class="c_collect_folder_unit bbox"
  >
    <div class="unit_wrapper cp"
      @dblclick="goToFolder()"
    >
      <img
        :src="require(`@/assets/images/custom/folder${readonly?'_readonly':''}.png`)"
        alt=""
        class="image un_sel"
      >
      <el-popover
        :visible-arrow="false"
        :offset="-5"
        placement="right-start"
        width="400"
        trigger="click"
        popper-class="c_collect_folder_unit_popover"
        v-model="switch_menu"
        v-if="!preview && show_menu"
      > 
        <div class="menu_group"
          ref="menu_group"
          v-show="!start_moving"
        >
          <div class="menu_list_group"
            v-if="0"
          >
            <div class="item un_sel cp"
              v-if="!readonly"
              @click="doRename()"
            >
              <img src="~@/assets/images/custom/menu/rename.png" alt="" class="icon">
              <div class="text">重新命名</div>
            </div>
          </div>
          <div class="menu_list_group">
            <div class="item un_sel cp"
              v-if="renameable"
              @click="doRename()"
            >
              <img src="~@/assets/images/custom/menu/rename.png" alt="" class="icon">
              <div class="text">重新命名</div>
            </div>
            <div class="item un_sel cp"
              @click="doShare()"
            >
              <div class="text">分享</div>
            </div>
            <div class="item un_sel cp"
              v-if="(readonly && p_folder.shareid) || !readonly"
              @click="startMove()"
            >
              <img src="~@/assets/images/custom/menu/move.png" alt="" class="icon">
              <div class="text">移动至</div>
              <img src="~@/assets/images/custom/menu/arrow.png" alt="" class="arrow">
            </div>
            <div class="item un_sel cp"
              v-if="(readonly && p_folder.shareid) || !readonly"
              @click="removeCollect();"
            >
              <img src="~@/assets/images/custom/menu/delete.png" alt="" class="icon">
              <div class="text">删除分组</div>
            </div>
            <div class="item un_sel cp"
              @click="startSelect();"
              v-if="!disable_select && ((readonly && p_folder.shareid) || !readonly)"
            >
              <img src="~@/assets/images/custom/menu/multiple.png" alt="" class="icon">
              <div class="text">多选分组</div>
            </div>
            <div class="item un_sel"
              v-if="0"
            >
              <div class="from">来源：C1220302｜妙行营销功能研发1.0</div>
            </div>
          </div>
        </div>
        <div class="moving_path_wrapper"
          v-if="start_moving"
          :style="{width: `${moving_path_width}px`, 'z-index': moving_path_z_index}"
        >
          <moving-path
            v-if="start_moving"
            :mask="false"
            :multiple="false"
            type="folder"
            :id="String(p_folder.id)"
            :current_folder="current_folder"
            :max_height="String(moving_max_height)"
            :filter_list="[p_folder]"
            @handleClose="doCloseMenu"
            @handleRemove="handleRemove"
            @handleSure="handleSure"
          ></moving-path>
        </div>
        <div
          class="menu_btn cp"
          slot="reference"
        ></div>
      </el-popover>
      <div
        class="select_group cp"
        v-show="switch_select"
      >
        <div class="select"
          @click.stop="doSelect()"
          :class="{on: p_folder.cf && p_folder.cf.selected}"
        ></div>
      </div>
    </div>
    <div class="name_group bbox">{{folder_name}}</div>
  </div>
</template>

<script>
import store from '@/store'
import tools from '@/api/tools'
import global from '@/api/global'
import MovingPath from '@/components/Common/MovingPath/Container.vue'
export default {
  components: {
    MovingPath,
  },
  props: {
    p_folder: {
      type: Object,
      default: undefined,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    switch_select: {
      type: Boolean,
      default: false,
    },
    disable_select: {
      type: Boolean,
      default: false,
    },
    current_folder: {
      type: Object,
      default: undefined,
    },
    call: { // 是否调用
      type: Boolean,
      default: false,
    },
    show_menu: {
      type: Boolean,
      default: true,
    },
  },
  data(){
    return{
      switch_menu:false,
      start_moving: false,
      moving_path_width: 0,
      moving_path_z_index: 0,
      moving_max_height: '451',
    }
  },
  computed: {
    folder_name(){
      const {p_folder} = this
      return p_folder?.name??''
    },
    readonly(){
      const {p_folder} = this
      const user_id = tools.getUserId()
      return Number(user_id) !== Number(p_folder.createuserid)
    },
    switch_moving_path(){
      return store.state.switch_moving_path;
    },
    renameable(){
      const {p_folder,} = this
      const { shareid, createuserid} = p_folder
      const user_id = tools.getUserId()
      return (Number(user_id) === Number(createuserid))
    }
  },
  watch: {
    close_task_menu_of_moving: function(val){
      if(val){
        this.doCloseMenu();
        this.$nextTick(function(){
          store.commit('set_close_task_menu_of_moving', false);
        })
        // console.log(this.switch_popover);
      }
    },
    switch_moving_path: function(val){
      if(val == false){
        this.doCloseMenu();
      }
    }
  },  
  methods: {
    goToFolder(){
      const {p_folder, $route, switch_select} = this
      if(switch_select){
        return
      }
      
      const collect_nav = [
        ...new Set([
          ...(store.state.collect_nav??[]),
          ...[p_folder],
        ])
      ]
      store.commit('set_collect_nav', collect_nav)
      this.$tools.setCookie('collect_nav', collect_nav)
      // 如果在我的收藏页面 则更改页面路由
      const {name} = $route
      if(name === 'fun_template_my_custom' || name === 'fun_template_my'){
        this.$router.push({name: 'fun_template_my_custom', params: {
          type: 'custom',
          folderid: p_folder.shareid || p_folder.id
        }})
      }
    },
    doRename(){
      this.switch_menu = false
      this.p_folder.rename()
    },
    doShare(){
      this.switch_menu = false
      this.p_folder.share()
    },


    startMove(){
      this.start_moving = true;
      const clientWidth = document.body.clientWidth;
      const clientHeight = document.body.clientHeight;
      const rect = this.$refs.menu_group.getBoundingClientRect();
      const left = rect.left;
      const top = rect.top;
      // 计算记录最右边宽度 有20边距
      const width = clientWidth - left - 20;
      // 计算记录最下边宽度 有20边距
      const height = clientHeight - top -20;
      this.moving_max_height = (height > 451) ? '451' : height;
      this.moving_path_width = width,
      this.moving_path_z_index = this.$utils.getZIndex();
      // this.switch_moving_path = true;
      store.commit('set_switch_moving_path', true);
    },
     /**
     * @description: 关闭菜单
     * @param {*}
     * @return {*}
     */
    doCloseMenu(){
      this.start_moving = false;
      this.switch_menu = false
      this.switch_popover = false;
    },
    /** 移除当前文件夹 */
    handleRemove(){
      console.log('folderUnit')
      this.$emit('handleRemove', this.p_folder)
    },
    startSelect(){
      this.$emit('handleStartSelect')
      this.doCloseMenu()
    },
    doSelect(){
      const {p_folder} = this
      p_folder.select()
    },
    async removeCollect(){
      this.doCloseMenu()
      const {p_folder} = this
      this.$openConfirm({
        title: '是否取消收藏', 
        desc: '', 
        sure: '是', 
        cancel: '否', 
        onSure: async ()=>{
          const res = await p_folder.removeCollect()
          if(res){
            this.$notice({desc: '移除成功', type: 'success'})
            this.handleRemove()
          }
        }, 
        onCancel: ()=>{

        }, 
      })
      
    },
    handleSure(aim){
      console.log({aim})
      this.$openConfirm({
        title:`是否移动到 '${aim.name}' ?`,
        desc:'',
        sure:'确定',
        cancel:'取消',
        onSure:async ()=>{
          let data = {
            ids: this.p_folder.id,
            type: 0,
            toid: aim.id
          }
          let res = await global.doPost({
            url: '/projectCustom/moveFolderOrTask',
            data,
            notice: '移动失败',
          })
          if(res.message=='success'){
            this.handleRemove()
            // 重新加载文件和文件夹
            
          }
        },
        onCancel:()=>{},
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c_collect_folder_unit{
  width: 100px;
  .unit_wrapper{
    @include transition;
    position     : relative;
    border-radius: 4px;
    &:hover{
      background-color: rgba(0,0,0,0.1);
      .menu_btn{
        transform: scale(1);
      }
    }
    .menu_btn{
      @include transition;
      @include background( 3px, 15px);
      transform    : scale(0);
      position     : absolute;
      right        : 0;
      top          : 3px;
      z-index      : 3;
      height       : 20px;
      width        : 20px;
      border-radius: 50%;
      overflow     : hidden;
      background: {
        image   : url(~@/assets/images/custom/menu_btn.png);
        position: center;
      }
    }
    .select_group{
      position: absolute;
      z-index: 3;
      top:3px;
      left: 5px;
      width: 22px;
      height: 22px;
      .select{
        background-image: url(~@/assets/images/custom/unselected.png);
        width: 100%;
        height: 100%;
        &.on{
          background-image: url(~@/assets/images/custom/selected.png);
        }
      }
    }

  }
  .name_group{
    @include multi_line_intercept(2);
    width: 100%;
    padding: 0 10px;
    text-align: center;
    // text-justify: inter-ideograph;
    text-align-last: center;
    word-break: break-all;
    width: 100%;
    padding: {
      // top: 10px;
    }
    font-size: 12px;
    line-height: 18px;
    height: 36px;
    color: #333;
  }
}
</style>

<style lang="scss">
.c_collect_folder_unit_popover{
  // background-color: #333;
  width: auto!important;
  border:none;
  padding: 0;
  margin-left: 0!important;
  .menu_group{
    @include flex;
    flex-direction: column;
    align-items: stretch;
  }
  .menu_list_group{
    @include flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    padding: 5px 0;
    &:last-child{
      border-bottom: none;
    }
    .item{
      @include flexsb;
      @include transition;
      @include bbox;
      &:hover{
        background-color: #eee;
      }
      height: 40px;
      padding: 0 10px;
    }
    .icon{
      flex-shrink: 0;
      display: none;
    }
    .arrow{
      flex-shrink: 0;
    }
    .text{
      @include bbox;
      padding: {
        // left: 13px;
      }
      flex: 1;
      color: #333;
      font-size: 14px;
    }
    .from{
      max-width: 180px;
      word-break: break-all;
      font-size: 12px;
      color: #666;
      line-height: 16px;
      text-align: justify;
      text-justify: inter-ideograph;
      text-align-last: left;
      padding: 5px 0;
    }
  }
  .moving_path_wrapper{
    position: absolute;
    top:0;
    left:0;
    min-height: 20px;
    // background-color: red;
    width: 100%;
  }
}
</style>
